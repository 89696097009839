import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./TaskPage.module.scss";
import {
  CustomButtosWithIcon,
  DetailItem,
  FileDownload,
} from "../../../Components";
import Recording from "../../../Components/Recording";
import { IFiles, ITaskPage } from "../../../types";
import { getFileName } from "../../../Utils";
import {
  useEditFreeTaskByIdMutation,
  useEditHolidayTasksByIdMutation,
} from "../../../store/services/tasks";
import DeleteTaskDialog from "../../../Dialogs/DeleteTaskDialog";
import { useNavigate } from "react-router-dom";
import { useAddImagesMutation } from "../../../store/services/photo";
import ComponentWithBorder from "../../../Components/ComponentWithBorder";
import DocumentCard from "../../../Components/SvgCompoents/DocumentCard";
import { Collapse } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
interface DataTask {
  data: ITaskPage;
}
export default function Free({ data }: DataTask) {
  const { t } = useTranslation();
  const [status, setStatus] = useState<string>("done");
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<IFiles>({});
  const [submit, setSubmit] = useState<boolean>(false);
  const [complain, setComplain] = useState<string>("");
  const [addImages] = useAddImagesMutation();
  const description = t("salaryDescription");
  const navigate = useNavigate();
  const [editFreeTaskById, { isSuccess }] = useEditFreeTaskByIdMutation();
  const file = useMemo(() => {
    return getFileName(data?.free_task?.file);
  }, [data?.free_task?.file]);
  const acc_file = useMemo(() => {
    return getFileName(data?.free_task?.acc_file);
  }, [data?.free_task?.acc_file]);
  const handleOpenDeleteTask = useCallback(() => {
    setOpen(true);
  }, []);
  const handleCloseDeleteTask = useCallback(() => {
    setOpen(false);
  }, []);
  const deleteTask = () => {
    editFreeTaskById({ id: data?.free_task?.id, status: "deleted" });
  };
  const handleSetFile = useCallback(
    (data: IFiles) => {
      setFiles({ ...files, ...data });
    },
    [files]
  );
  const handleDeleteFile = useCallback((name: string, id?: number) => {
    setFiles((prev: any) => {
      const prevFiles = { ...prev };
      delete prevFiles[name];
      return prevFiles;
    });
  }, []);
  const handleChangeStatus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(e.target.value);
    },
    [status]
  );
  const handleComplain = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComplain(e.target.value);
    },
    []
  );
  useEffect(() => {
    if (isSuccess) {
      toast.success(t("taskHasBeenDeleted"));
      handleCloseDeleteTask();
      navigate("/profile/dashboard");
    }
  }, [isSuccess]);
  return (
    <div className={`w-100`}>
      <DeleteTaskDialog
        open={open}
        handleClose={handleCloseDeleteTask}
        handleDelete={deleteTask}
      />
      <div className={styles.taskType}>
        <div className="d-flex justify-content-between flex-nowrap">
          <div className={`${styles.taskTypeHeading}`}>
            {t(`${data?.task_type?.name}`)}{" "}
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className={`${styles.taskStatus} ${styles[data?.status]}`}>
              {t(`${data?.status}`)}
            </div>
            {data?.status === "created" && (
              <div
                onClick={handleOpenDeleteTask}
                className={`${styles.taskStatus} ${styles.deleteTask}`}
              >
                {t("delete")} <DeleteIcon />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <div className={styles.heading}>{t("detail")} </div>
            <div className={styles.detailCont}>
              {data?.task_name && (
                <DetailItem name={t("taskName")} value={data?.task_name} />
              )}
              {data?.free_task?.comment && (
                <DetailItem
                  name={t("comment")}
                  value={data?.free_task?.comment}
                />
              )}
              {data?.free_task?.acc_comment && (
                <DetailItem
                  name={t("accComment")}
                  value={data?.free_task?.acc_comment}
                />
              )}
              {data?.created_at && (
                <DetailItem
                  name={t("createdAt")}
                  value={moment(data?.created_at).format("DD-MMMM-YYYY")}
                />
              )}
              {data?.free_task?.recording && (
                <div className={styles.application}>
                  <h4 className={styles.subHeading}>{t("voiceMessage")}</h4>
                  <Recording src={data?.free_task?.recording} />
                </div>
              )}
            </div>
          </div>
          {data?.free_task?.file && (
            <div className={styles.application}>
              <h4 className={styles.subHeading}>{t("attachedFiles")}</h4>
              <div className={`d-flex ${styles.cardCont}`}>
                <FileDownload
                  url={`${process.env.REACT_APP_API_KEY}/${data?.free_task.file}`}
                  status={data?.status}
                  title={file}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {data.status !== "created" && (
        <div className={styles.taskType}>
          <div className={styles.heading}> {t("accountantAttachedFiles")} </div>
          <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
            <label
              className={`${styles.cardUploadItem} d-flex flex-column position-relative`}
            >
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e: any) => {
                  const file: any = e?.target?.files[0];
                  if (file) {
                    const formdata = new FormData();
                    formdata.append("file_url", file);
                    addImages(formdata).then((res: any) => {
                      if (res.data) {
                        handleSetFile({ acc_file: res.data });
                      }
                    });
                  }
                }}
              />
              <span className={styles.cardLabel}>{t("document")}</span>
              {files?.acc_file ? (
                <ComponentWithBorder
                  onClick={() => handleDeleteFile("acc_file")}
                  img={`${process.env.REACT_APP_API_KEY}${files.acc_file}`}
                />
              ) : acc_file ? (
                <FileDownload
                  url={`${process.env.REACT_APP_API_KEY}/${data?.employee_bonus?.acc_file}`}
                  status={data?.status}
                  title={acc_file}
                  deleteItem={() => {
                    console.log("dgf");
                  }}
                />
              ) : (
                <div className="d-flex flex-column align-items-center">
                  <DocumentCard />
                  <span className={styles.cardTitle}>
                    {t("attachFilesHere")}
                  </span>
                </div>
              )}
            </label>
          </div>
        </div>
      )}
      {data.status === "answeredByAccountant" && (
        <div className={styles.taskType}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className={styles.heading}> {t("acceptTask")} </div>
              {data?.status === "answeredByAccountant" && (
                <CustomButtosWithIcon
                  title={t("answer")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                  onClick={() => {
                    setSubmit(true);
                    const edtData =
                      data?.status === "created"
                        ? {
                            ...files?.files?.map((el) => ({ url: el })),
                            status: "answeredByAccountant",
                            id: data?.free_task?.id,
                          }
                        : {
                            status,
                            user_complain: complain,
                            id: data?.free_task?.id,
                          };
                    if (!complain && status === "dispute") return;
                    editFreeTaskById(edtData);
                  }}
                />
              )}
            </div>
            <div className="d-flex flex-column align-items-start gap-2">
              <label className={styles.customRadioItem}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="contactChoice1"
                  name="status"
                  value="done"
                  onChange={handleChangeStatus}
                  checked={status === "done"}
                />
                <div className={styles.customRadioItemLabel}>
                  {t("finishTask")}
                </div>
              </label>
              <label className={styles.customRadioItem}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="contactChoice2"
                  name="status"
                  value="dispute"
                  checked={status === "dispute"}
                  onChange={handleChangeStatus}
                />
                <div className={styles.customRadioItemLabel}>
                  {" "}
                  {t("disputeTask")}{" "}
                </div>
              </label>
              <Collapse in={status === "dispute"} timeout="auto">
                <div className="mt-2">
                  <textarea
                    value={complain}
                    className={styles["complain-textarea"]}
                    onChange={handleComplain}
                  />
                  {submit && !complain && (
                    <div className="error">{t("required")}</div>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      )}
      {data.status === "dispute" && (
        <div className={styles.taskType}>
          <div className="d-flex flex-column">
            <div className={styles.heading}> {t("userComplain")} </div>
            <span className={styles.complain}>
              {data?.free_task?.user_complain}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
