import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Notification from "../SvgCompoents/Notification";
import icon1 from "../../assets/briefcase.svg";
import icon2 from "../../assets/buildings.svg";
import icon3 from "../../assets/calendar.svg";
import styles from "./DashboardCard.module.scss";
import { useCallback, useState } from "react";
import { Dashboard, Menu, PrivatePage } from "../../Pages";

interface IProps {
  task_type?: string;
  task_name?: string;
  company_name?: string;
  created_at?: string;
  manager?: string;
  status?: string;
  id: number;
}
export default function DashboardCard({
  task_type,
  task_name,
  company_name,
  created_at,
  status,
  id,
  manager,
}: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    //@ts-ignore
    if (isNaN(date)) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    //@ts-ignore
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const formattedDate = formatDate(created_at);
  const handleOpen = () => {
    setSelectedId(id);
    setIsOpen(true);
  };

  return (
    <Link to={`/profile/dashboard/${id}`} className={styles.dashboardCard}>
      <div
        className={`d-flex justify-content-between ${styles.dashboardCardTop}`}
      >
        <span className={`${styles.taskName}`}>{task_name}</span>
        <div
          className={
            status === "dispute" ? styles.buttonDivDispute : styles.buttonDiv
          }
        >
          <span className={styles.buttonTitle}>Urgant</span>
        </div>
        {/* <div className={`${styles.notificationCont}`}>
          <Notification />
          <div className={`${styles.round}`}></div>
        </div> */}
      </div>
      <div
        className={`d-flex w-100 align-items-center justify-content-between ${styles.dashboardCardCont}`}
      >
        <div className={`w-100 ${styles.dashboardCardMain}`}>
          <div className={styles.dashboardCardMainItem}>
            <div className={styles.iconDiv}>
              <img className={styles.iconStyle} src={icon1} />
            </div>
            <span className={styles.textStyle}>{task_type}</span>
          </div>
          <div className={styles.dashboardCardMainItem}>
            <div className={styles.iconDiv}>
              <img className={styles.iconStyle} src={icon2} />
            </div>
            <span className={styles.textStyle}>{company_name}</span>
          </div>
          {manager && (
            <div className="d-flex align-items-center mb-1">
              <div
                className={`d-flex justify-content-center align-items-center ${styles.managerSrc}`}
              >
                <img src="/managerDefaultImg.svg" />
              </div>
              <span className={styles.managerName}>{manager}</span>
            </div>
          )}
          <div className={styles.dashboardCardMainItem}>
            <div className={styles.iconDiv}>
              <img className={styles.iconStyle} src={icon3} />
            </div>
            <span className={styles.textStyle}>{formattedDate}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}
