import { Formik, FormikHelpers, Form, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomButtosWithIcon,
  CustomInput,
  CustomRadio,
} from "../../../../Components";
import DocumentCard from "../../../../Components/SvgCompoents/DocumentCard";
import styles from "../CreateTask.module.scss";
import Recording from "../../../../Components/Recording";
import { useAppSelector } from "../../../../store";
import {
  useAddTasksHolidayMutation,
  useAddTasksMutation,
  useGetbankQuery,
} from "../../../../store/services/tasks";
import { useAddImagesMutation } from "../../../../store/services/photo";
import ComponentWithBorder from "../../../../Components/ComponentWithBorder";
import { toast } from "react-toastify";
import { IFiles } from "../../../../types";
import closeIcon from "../../../../assets/cl.svg";
import docIcon from "../../../../assets/paperclip-2.svg";

interface IProps {
  open?: any;
  handleClose?: any;
  setShowCreateVacation?: any;
}

const CreateVacation: React.FC<IProps> = (props) => {
  const { open, handleClose, setShowCreateVacation } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const taskData = useAppSelector((state) => state.createTask.taskData);
  const [recording, setRecording] = useState<string>();
  const [files, setFiles] = useState<IFiles>({});
  const [submited, setSubmited] = useState(false);
  const { data: banks } = useGetbankQuery({ limit: 10, offset: 0 });
  const [addImages, { data }] = useAddImagesMutation();
  const [addTasksHolidayRequest] = useAddTasksHolidayMutation();
  interface Values {
    task_name: string;
  }
  const SignupSchema = Yup.object().shape({
    task_name: Yup.string().required(t("required")),
  });
  const handleSetFile = useCallback(
    (data: IFiles) => {
      setFiles({ ...files, ...data });
    },
    [files]
  );
  const handleRecording = useCallback((url: string) => {
    setRecording(url);
  }, []);
  const handleDeleteFile = useCallback((name: keyof typeof files) => {
    setFiles((prev: IFiles) => {
      const prevFiles = { ...prev };
      delete prevFiles[name];
      return prevFiles;
    });
  }, []);
  return (
    <div className={styles.cont}>
      <div className={styles.header}>
        <span className={styles.headerTitle}>{t(`EmployeeHoliday`)}</span>
        <div
          className={styles.closeButton}
          onClick={() => {
            setShowCreateVacation(false);
          }}
        >
          <img src={closeIcon} />
        </div>
      </div>
      <div className={styles.main}>
        <Formik
          initialValues={{
            // salary: 0,
            task_name: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            if (files?.application) {
              addTasksHolidayRequest({
                ...values,
                recording: recording ? recording : "",
                status: "created",
                task_type: taskData?.task_type_id
                  ? taskData?.task_type_id
                  : localStorage.getItem("taskTypeId"),
                company: taskData?.company_id
                  ? taskData?.company_id
                  : localStorage.getItem("cmpId"),
                ...files,
              }).then((res: any) => {
                if (res?.data?.id) {
                  toast.success("Գործընթացը հաջողությամբ կատարվել է");
                  navigate("/profile/dashboard");
                }
              });
            }
            // setAtcive({ step: 2 })
          }}
        >
          {({ errors, touched, values }: any) => (
            <Form className="w-100">
              {/* <div className='d-flex justify-content-between mb-3'>
              <h1 className={styles.pageHeading}>{t(`EmployeeHoliday`)}</h1>
     
            </div> */}
              <div className={styles.inputDiv}>
                <CustomInput
                  name="task_name"
                  title={t("taskName")}
                  classInput={styles.inputeStyle}
                />
              </div>

              <div className="d-flex justify-content-between">
                <div className="position-relative">
                  <div className="d-flex flex-column">
                    <div
                      className={`${styles.sectionHeading} ${styles.cardContVacation}`}
                    >
                      <span className={styles.titleStyle}>
                        {t("attachYourDocument")}
                      </span>
                    </div>
                    <div
                      className={`d-flex align-items-center ${styles.documentLabel}`}
                    >
                      <label
                        className={`${styles.cardUploadItem} d-flex position-relative`}
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const file: File | null =
                              e?.target?.files && e?.target?.files?.length > 0
                                ? e?.target?.files[0]
                                : null;
                            if (file) {
                              const formdata = new FormData();
                              formdata.append("file_url", file);
                              addImages(formdata).then((res: any) => {
                                if (res.data) {
                                  handleSetFile({ application: res.data });
                                }
                              });
                            }
                          }}
                        />
                        <div className={styles.defautDoc}>
                          {" "}
                          <img className={styles.iconDoc} src={docIcon} />
                          <span className={styles.label}>
                            {t("attachFilesHere")}
                          </span>
                        </div>
                        {files?.application && (
                          <ComponentWithBorder
                            onClick={() => handleDeleteFile("application")}
                            img={`${process.env.REACT_APP_API_KEY}${files.application}`}
                          />
                        )}
                      </label>
                      {/* <span className={styles.label}>{t('attachFilesHere')}</span> */}
                    </div>
                    {submited && !files?.application && (
                      <div className="error position-absolute bottom-0">
                        {t("required")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`${styles.sectionHeading} ${styles.cardContHeading}`}
                >
                  <span className={styles.titleStyle}>{t("voiceMessage")}</span>
                </div>
                <Recording handleRecording={handleRecording} />
              </div>
              <div className={styles.buttonDiv}>
                <CustomButtosWithIcon
                  title={t("deleteInfoDialogCancelButtonTitle")}
                  type={"button"}
                  onClick={() => {
                    setShowCreateVacation(false);
                  }}
                  icon=""
                  titleColor="black"
                  color="white"
                />
                <CustomButtosWithIcon
                  title={t("create")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                  onClick={() => setSubmited(true)}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateVacation;
