import styles from './CustomButtosWithIcon.module.scss';
interface IProps {
  title?: any,
  titleColor?:any;
  disabled?: boolean,
  type: any,
  icon?: any,
  color?: string,
  onClick?: any
}
const CustomButtosWithIcon = (props: IProps) => {
  return <button
    style={{ background: props?.color, color:props.titleColor}}
    className={`${styles.button} d-flex justify-content-center align-items-center`}
    disabled={props.disabled}
    type={props?.type}
    onClick={props.onClick}>
    {props?.icon}
    {props?.title && <div className={styles.title}>{props?.title}</div>}
  </button>
}
export default CustomButtosWithIcon;