import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditSalaryTasksByIdMutation } from '../../../store/services/tasks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddImagesMutation } from '../../../store/services/photo';
import { toast } from 'react-toastify';
import { getFileName } from '../../../Utils';
import { IFiles, ITaskPage } from '../../../types';
interface DataTask {
  data: ITaskPage
}
export default function Registration({ data }: DataTask) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [submit, setSubmit] = useState<boolean>(false);
  const [files, setFiles] = useState<IFiles>({});
  const [savedFiles, setSavedFiles] = useState<IFiles>({});
  const [addImages] = useAddImagesMutation();
  const [editSalaryTasksById] = useEditSalaryTasksByIdMutation();

  useEffect(() => {
    if (data?.employee_salary) {
      const { command, contract } = data?.employee_salary
      setSavedFiles({ command, contract })
    }
  }, [data])
  const handleSetFile = useCallback((data: IFiles) => {
    setFiles({ ...files, ...data })
  }, [files])

  const handleDeleteFile = useCallback((name: keyof typeof savedFiles) => {

    if (savedFiles[name]) {
      setSavedFiles((prev: IFiles) => {
        const prevFiles = { ...prev };
        delete prevFiles[name];
        return prevFiles
      })
    } else {
      setFiles((prev: IFiles) => {
        const prevFiles = { ...prev };
        delete prevFiles[name];
        return prevFiles
      })
    }

  }, [savedFiles])


  const command = useMemo(() => {
    return getFileName(savedFiles?.command)
  }, [savedFiles?.command]);


  const contract = useMemo(() => {
    return getFileName(savedFiles?.contract)
  }, [savedFiles?.contract]);

  return (
    <div className={`w-100`}>
      <div className={styles.taskType}>
        <div className='d-flex justify-content-between flex-nowrap'>
          <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
          <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          <div>
            <div className={styles.heading}>{t('detail')} </div>
            <div className={styles.detailCont}>
              {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
              {data?.employee_salary?.employee_name && <DetailItem name={t('employeeName')} value={data?.employee_salary?.employee_name} />}
              {data?.employee_salary && data?.employee_salary?.salary !== 0 && <DetailItem name={t('salary')}
                value={new Intl.NumberFormat(
                  'en-US',
                ).format(data.employee_salary.salary)} />}
              {data?.employee_salary?.position && <DetailItem name={t('role')} value={data?.employee_salary?.position} />}
              {data?.employee_salary?.change_date && <DetailItem name={t('changeDate')} value={moment(data?.employee_salary?.change_date).format('DD-MMMM-YYYY')} />}
              {data?.employee_salary?.recording && <div className={styles.application}>
                <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
                <Recording src={data?.employee_salary?.recording} />
              </div>}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.taskType}>
        <div className={`d-flex justify-content-between align-items-center ${styles.heading}`}>
          <span> {t('accountantAttachedFiles')} </span>
          {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
            title={t("answer")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                  fill="#FEFEFE"
                />
              </svg>
            }
            type="submit"
            onClick={() => {
              setSubmit(true)
              const savedData = { ...savedFiles, ...files }
              if (savedData && savedData.command && savedData.contract) {
                editSalaryTasksById({ ...savedData, id: data?.employee_salary?.id, status: 'answeredByAccountant' })
                  .then((res: any) => {
                    if (res?.data?.id) {
                      toast.success('Գործընթացը հաջողությամբ կատարվել է');
                      navigate('/profile/dashboard')
                    }
                  })
              }
            }}
          />}
        </div>
        <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
          <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
            <input type='file' style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                if (file) {
                  const formdata = new FormData();
                  formdata.append('file_url', file)
                  addImages(formdata)
                    .then((res: any) => {
                      if (res.data) {
                        handleSetFile({ command: res.data })
                      }
                    })
                }
              }} />
            <span className={styles.cardLabel}>{t('employmentContract')}</span>
            {files?.command
              ? <ComponentWithBorder onClick={() => handleDeleteFile('command')}
                img={`${process.env.REACT_APP_API_KEY}${files.command}`} />
              : (command
                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles?.command}`}
                  status={data?.status}
                  title={command}
                  deleteItem={() => handleDeleteFile('command')}
                />
                : <div className='d-flex flex-column'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                  {submit && !files?.command && <div className='error error-text'>{t('required')}</div>}
                </div>)}
          </label>
          <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
            <input type='file' style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                if (file) {
                  const formdata = new FormData();
                  formdata.append('file_url', file)
                  addImages(formdata)
                    .then((res: any) => {
                      if (res.data) {
                        handleSetFile({ contract: res.data })
                      }
                    })
                }
              }} />
            <span className={styles.cardLabel}>{t('employmentOrder')}</span>
            {files?.contract
              ? <ComponentWithBorder
                onClick={() => handleDeleteFile('contract')}
                img={`${process.env.REACT_APP_API_KEY}${files.contract}`} />
              : (contract
                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles?.contract}`}
                  status={data?.status}
                  title={contract}
                  deleteItem={() => handleDeleteFile('contract')}
                />
                : <div className='d-flex flex-column'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                  {submit && !files?.contract && <div className='error error-text'>{t('required')}</div>}
                </div>)}
          </label>
        </div>
      </div>
        {data?.status === "dispute" && <div className='d-flex flex-column mt-3'>
          <div className={styles.heading}>{t('userComplain')} </div>
          <span className={styles.complain}>{data?.employee_salary?.user_complain}</span>
        </div>}
    </div>
  );
}


