import { useCallback, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { CustomButtosWithIcon, CustomDayPicker, CustomInput, CustomRadio } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import styles from './CreateTask.module.scss';
import { useAddTasksBonusMutation } from '../../../store/services/tasks';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import { useAddImagesMutation } from '../../../store/services/photo';
import { useAppSelector } from '../../../store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IFiles } from '../../../types';
import Recording from '../../../Components/Recording';
import closeIcon from "../../../assets/cl.svg"
import docIcon from "../../../assets/paperclip-2.svg"

interface IProps {
    setCreateBonus?: any
}

const Bonus: React.FC<IProps> = (props) => {
    const { setCreateBonus } = props
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [submited, setSubmited] = useState(false);
    const [files, setFiles] = useState<IFiles>({});
    const [dismissDate, setDismissDate] = useState<string>();
    const [recording, setRecording] = useState<string>();
    const [addTasksBonus] = useAddTasksBonusMutation();
    const [addImages, { data }] = useAddImagesMutation();
    const taskData = useAppSelector((state) => state.createTask.taskData);
    interface Values {
        type: string
        explanation?: string,
        task_name: string
    }
    const SignupSchema = Yup.object().shape({
        type: Yup.string()
            .required(t('required')),
        employee_name: Yup.string()
            .required(t('required')),
        task_name: Yup.string()
            .required(t('required')),
        price: Yup.number()
            .required(t('required'))
            .min(0, t('required')),
    });
    const handleSetFile = useCallback((data: IFiles) => {
        setFiles({ ...files, ...data })
    }, [files])
    const handleDeleteFile = useCallback((name: keyof typeof files) => {
        setFiles((prev: IFiles) => {
            const prevFiles = { ...prev };
            delete prevFiles[name];
            return prevFiles
        })
    }, [])
    const taskTypeId = localStorage.getItem('taskTypeId')
    const handleSelectDate = useCallback((value: string) => {
        setDismissDate(value)
    }, [])
    const handleRecording = useCallback((url: string) => {
        setRecording(url)
    }, [])

    return (
        <div className={styles.cont}>
            <div className={styles.header}>
                <span className={styles.headerTitle}>{t('EmployeeBonus')}</span>
                <div className={styles.closeButton} onClick={() => { setCreateBonus(false) }}><img src={closeIcon} /></div>
            </div>
            <div className={styles.main}>
                <Formik
                    initialValues={{
                        type: '0',
                        task_name: ''
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(
                        values: Values,
                        { setSubmitting }: FormikHelpers<Values>
                    ) => {
                        addTasksBonus({
                            ...values,
                            ...files,
                            type: +values.type,
                            status: 'created',
                            recording: recording ? recording : '',
                            task_type: +taskData?.task_type_id ? +taskData?.task_type_id : parseInt(taskTypeId as string),
                            company: +taskData?.company_id ? +taskData?.company_id : parseInt(localStorage.getItem('cmpId') as string),
                        })
                            .then((res: any) => {
                                if (res?.data?.id) {
                                    toast.success('Գործընթացը հաջողությամբ կատարվել է');
                                    navigate('/profile/dashboard')
                                }
                            })

                    }}
                >
                    {({ errors, touched, values }: any) => (
                        <Form className='w-100' >
                            {/* <div className='d-flex justify-content-between'>
                                <h1 className={styles.pageHeading}>{t('EmployeeBonus')}</h1>
                                <div className='d-none d-md-flex'>

                                </div>
                            </div> */}
                            <div className={styles.taskNameDiv}>
                                <CustomInput
                                    name='task_name'
                                    type='text'
                                    title={t('taskName')}
                                    classInput={styles.inputeStyle} />
                            </div>
                            <CustomRadio
                                radioArray={[
                                    { label: t("bonus"), value: "0" },
                                    { label: t('keep'), value: "1" },
                                ]}
                                label={t('bonusType')}
                                name={'type'}
                            />
                                                            <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}><span className={styles.titleStyle}>{t('file')}</span></div>
                            <label className={`${styles.cardUploadItem} ${styles.documentLabel} d-flex flex-column position-relative`}>
                                <input
                                    type='file'
                                    style={{ display: 'none' }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                                        if (file) {
                                            const formdata = new FormData();
                                            formdata.append('file_url', file)
                                            addImages(formdata)
                                                .then((res: any) => {
                                                    if (res.data) {
                                                        handleSetFile({ file: res.data })
                                                    }
                                                })
                                        }
                                    }}
                                />
                                  <div className={styles.defautDoc}> <img className={styles.iconDoc} src={docIcon} /><span className={styles.label}>{t('attachFilesHere')}</span></div>
                                {files?.file && <ComponentWithBorder
                                    onClick={() => handleDeleteFile('file')}
                                    img={`${process.env.REACT_APP_API_KEY}${files.file}`} />}

                                {/* <span className={styles.cardTitle}>{t('attachFilesHere')}</span> */}
                            </label>
                            <div className={styles.wrapDiv}>
                            <CustomInput name='employee_name' title={t('employeeName')} classInput={styles.inputeStyle}/>
                            <CustomInput name='price' title={t('price')} type='number' classInput={styles.inputeStyle} />
                            <CustomInput name='comment' title={t('comment')} classInput={styles.inputeStyle} />
                            </div>
                            <div>
                            <div className={styles.sectionHeading}><span className={styles.titleStyle}>{t('voiceMessage')}</span></div>
                                <Recording handleRecording={handleRecording} />
                            </div>
                            <div className={styles.buttonDiv}>
                                <CustomButtosWithIcon
                                    title={t('deleteInfoDialogCancelButtonTitle')}
                                    type={'button'}
                                    onClick={() => { setCreateBonus(false) }}
                                    icon=''
                                    titleColor="black"
                                    color="white"
                                />
                                <CustomButtosWithIcon
                                    title={t('create')}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                                    </svg>}
                                    type='submit'
                                    onClick={() => setSubmited(true)}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </div>

    );
}


export default Bonus