import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./TaskPage.module.scss";
import { DetailItem, FileDownload } from "../../../Components";
import Recording from "../../../Components/Recording";
import { getFileName } from "../../../Utils";
import { ITaskPage } from "../../../types";
import DeleteTaskDialog from "../../../Dialogs/DeleteTaskDialog";
import { useEditProvisionTasksByIdMutation } from "../../../store/services/tasks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
interface DataTask {
  data: ITaskPage;
}
export default function Provision({ data }: DataTask) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [editProvisionTasksById, { isSuccess }] =
    useEditProvisionTasksByIdMutation();
  const description = t("salaryDescription");
  const first_file = useMemo(() => {
    return getFileName(data?.employee_provision?.first_file);
  }, [data?.employee_provision?.first_file]);
  const second_file = useMemo(() => {
    return getFileName(data?.employee_provision?.second_file);
  }, [data?.employee_provision?.second_file]);
  const handleOpenDeleteTask = useCallback(() => {
    setOpen(true);
  }, []);
  const handleCloseDeleteTask = useCallback(() => {
    setOpen(false);
  }, []);
  const deleteTask = () => {
    editProvisionTasksById({
      id: data?.employee_provision?.id,
      status: "deleted",
    });
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(t("taskHasBeenDeleted"));
      handleCloseDeleteTask();
      navigate("/profile/dashboard");
    }
  }, [isSuccess]);
  return (
    <div className={`w-100`}>
      <DeleteTaskDialog
        open={open}
        handleClose={handleCloseDeleteTask}
        handleDelete={deleteTask}
      />
      <div className={styles.taskType}>
        <div className="d-flex justify-content-between flex-nowrap">
          <div className={`${styles.taskTypeHeading}`}>
            {t(`${data?.task_type?.name}`)}{" "}
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className={`${styles.taskStatus} ${styles[data?.status]}`}>
              {t(`${data?.status}`)}
            </div>
            {data?.status === "created" && (
              <div
                onClick={handleOpenDeleteTask}
                className={`${styles.taskStatus} ${styles.deleteTask}`}
              >
                {t("delete")} <DeleteIcon />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <div className={styles.heading}>{t("detail")} </div>
            <div className={styles.detailCont}>
              {data?.task_name && (
                <DetailItem name={t("taskName")} value={data?.task_name} />
              )}
              <DetailItem
                name={t("provisionType")}
                value={
                  data?.employee_provision?.type === 0
                    ? t("disease")
                    : t("pregnancy")
                }
              />
              {data?.employee_provision?.recording && (
                <div className={styles.application}>
                  <h4 className={styles.subHeading}>{t("voiceMessage")}</h4>
                  <Recording src={data?.employee_provision?.recording} />
                </div>
              )}
            </div>
          </div>
          {data?.employee_provision?.first_file &&
            data?.employee_provision.second_file && (
              <div className={styles.application}>
                <h4 className={styles.subHeading}>{t("accost")}</h4>
                <div className={`d-flex ${styles.cardCont}`}>
                  <FileDownload
                    url={`${process.env.REACT_APP_API_KEY}/${data?.employee_provision.first_file}`}
                    status={data?.status}
                    title={first_file}
                  />
                  <FileDownload
                    url={`${process.env.REACT_APP_API_KEY}/${data?.employee_provision.second_file}`}
                    status={data?.status}
                    title={second_file}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      {data.status === "done" && (
        <div className={styles.taskType}>
          <>
            <div className={styles.heading}> {t("accountantSalary")} </div>
            {data?.employee_provision && (
              <div>
                {description.replace(
                  "<<X<<",
                  new Intl.NumberFormat("en-US").format(
                    data?.employee_provision?.salary
                  )
                )}
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}
