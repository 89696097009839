import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./TaskPage.module.scss";
import {
  CustomButtosWithIcon,
  DetailItem,
  FileDownload,
} from "../../../Components";
import DocumentCard from "../../../Components/SvgCompoents/DocumentCard";
import ComponentWithBorder from "../../../Components/ComponentWithBorder";
import Recording from "../../../Components/Recording";
import {
  useEditDismissalTasksByIdMutation,
  useEditSalaryTasksByIdMutation,
  usePrefetch,
} from "../../../store/services/tasks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAddImagesMutation } from "../../../store/services/photo";
import { Collapse } from "@mui/material";
import { getFileName } from "../../../Utils";
import { IFiles, ITaskPage } from "../../../types";
import DeleteTaskDialog from "../../../Dialogs/DeleteTaskDialog";
import { toast } from "react-toastify";
interface DataTask {
  data: ITaskPage;
}
export default function ChangeSalaryPosition({ data }: DataTask) {
  const { t } = useTranslation();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<IFiles>({});
  const [submit, setSubmit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("done");
  const [complain, setComplain] = useState<string>("");
  const navigate = useNavigate();
  // const { data, isLoading } = useGetTasksByIdQuery({ id: params.id });
  const [addImages] = useAddImagesMutation();
  const [editSalaryTasksById, { isSuccess }] = useEditSalaryTasksByIdMutation();
  const handleSetFile = useCallback(
    (data: IFiles) => {
      setFiles({ ...files, ...data });
    },
    [files]
  );
  const handleChangeStatus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(e.target.value);
    },
    [status]
  );
  const handleComplain = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComplain(e.target.value);
    },
    []
  );

  const handleDeleteFile = useCallback((name: keyof typeof files) => {
    setFiles((prev: IFiles) => {
      const prevFiles = { ...prev };
      delete prevFiles[name];
      return prevFiles;
    });
  }, []);

  const command = useMemo(() => {
    return getFileName(data?.employee_salary?.command);
  }, [data?.employee_salary?.command]);

  const contract = useMemo(() => {
    return getFileName(data?.employee_salary?.contract);
  }, [data?.employee_salary?.contract]);
  const handleOpenDeleteTask = useCallback(() => {
    setOpen(true);
  }, []);
  const handleCloseDeleteTask = useCallback(() => {
    setOpen(false);
  }, []);
  const deleteTask = () => {
    editSalaryTasksById({ id: data?.employee_salary?.id, status: "deleted" });
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(t("taskHasBeenDeleted"));
      handleCloseDeleteTask();
      navigate("/profile/dashboard");
    }
  }, [isSuccess]);
  return (
    <div className={`w-100`}>
      <DeleteTaskDialog
        open={open}
        handleClose={handleCloseDeleteTask}
        handleDelete={deleteTask}
      />
      <div className={styles.taskType}>
        <div className="d-flex justify-content-between flex-nowrap">
          <div className={`${styles.taskTypeHeading}`}>
            {t(`${data?.task_type?.name}`)}{" "}
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className={`${styles.taskStatus} ${styles[data?.status]}`}>
              {t(`${data?.status}`)}
            </div>
            {data?.status === "created" && (
              <div
                onClick={handleOpenDeleteTask}
                className={`${styles.taskStatus} ${styles.deleteTask}`}
              >
                {t("delete")} <DeleteIcon />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <div className={styles.heading}>{t("detail")} </div>
            <div className={styles.detailCont}>
              {data?.task_name && (
                <DetailItem name={t("taskName")} value={data?.task_name} />
              )}
              {data?.employee_salary?.employee_name && (
                <DetailItem
                  name={t("employeeName")}
                  value={data?.employee_salary?.employee_name}
                />
              )}
              {data?.employee_salary && data?.employee_salary?.salary !== 0 && (
                <DetailItem
                  name={t("salary")}
                  value={new Intl.NumberFormat("en-US").format(
                    data?.employee_salary?.salary
                  )}
                />
              )}
              {data?.employee_salary?.position && (
                <DetailItem
                  name={t("role")}
                  value={data?.employee_salary?.position}
                />
              )}
              {data?.employee_salary?.change_date && (
                <DetailItem
                  name={t("changeDate")}
                  value={moment(data?.employee_salary?.change_date).format(
                    "DD-MMMM-YYYY"
                  )}
                />
              )}
              {data?.employee_salary?.recording && (
                <div className={styles.application}>
                  <h4 className={styles.subHeading}>{t("voiceMessage")}</h4>
                  <Recording src={data?.employee_salary?.recording} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {data.status !== "created" && (
        <div className={styles.taskType}>
          <>
            <hr />
            <div className={styles.heading}>
              {" "}
              {t("accountantAttachedFiles")}{" "}
            </div>
            <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
              <label
                className={`${styles.cardUploadItem} d-flex flex-column position-relative`}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const file: File | null =
                      e?.target?.files && e?.target?.files?.length > 0
                        ? e?.target?.files[0]
                        : null;
                    if (file) {
                      const formdata = new FormData();
                      formdata.append("file_url", file);
                      addImages(formdata).then((res: any) => {
                        if (res.data) {
                          handleSetFile({ command: res.data });
                        }
                      });
                    }
                  }}
                />
                <span className={styles.cardLabel}>
                  {t("employmentContract")}
                </span>
                {files?.command ? (
                  <ComponentWithBorder
                    onClick={() => handleDeleteFile("command")}
                    img={`${process.env.REACT_APP_API_KEY}${files.command}`}
                  />
                ) : command ? (
                  <FileDownload
                    url={`${process.env.REACT_APP_API_KEY}/${data?.employee_salary?.command}`}
                    status={data?.status}
                    title={command}
                  />
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <DocumentCard />
                    <span className={styles.cardTitle}>
                      {t("attachFilesHere")}
                    </span>
                  </div>
                )}
              </label>
              <label
                className={`${styles.cardUploadItem} d-flex flex-column position-relative`}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const file: File | null =
                      e?.target?.files && e?.target?.files?.length > 0
                        ? e?.target?.files[0]
                        : null;
                    if (file) {
                      const formdata = new FormData();
                      formdata.append("file_url", file);
                      addImages(formdata).then((res: any) => {
                        if (res.data) {
                          handleSetFile({ contract: res.data });
                        }
                      });
                    }
                  }}
                />
                <span className={styles.cardLabel}>{t("employmentOrder")}</span>
                {files?.contract ? (
                  <ComponentWithBorder
                    onClick={() => handleDeleteFile("employment_order")}
                    img={`${process.env.REACT_APP_API_KEY}${files.contract}`}
                  />
                ) : contract ? (
                  <FileDownload
                    url={`${process.env.REACT_APP_API_KEY}/${data?.employee_salary?.contract}`}
                    status={data?.status}
                    title={contract}
                  />
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <DocumentCard />
                    <span className={styles.cardTitle}>
                      {t("attachFilesHere")}
                    </span>
                  </div>
                )}
              </label>
            </div>
          </>
        </div>
      )}
      {data.status === "answeredByAccountant" && (
        <div className={styles.taskType}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className={styles.heading}> {t("acceptTask")} </div>
              {data?.status === "answeredByAccountant" && (
                <CustomButtosWithIcon
                  title={t("answer")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                  onClick={() => {
                    setSubmit(true);
                    const edtData =
                      data?.status === "created"
                        ? {
                            ...files,
                            status: "answeredByAccountant",
                            id: data?.employee_salary?.id,
                          }
                        : {
                            status,
                            user_complain: complain,
                            id: data?.employee_salary?.id,
                          };
                    if (status === "dispute" && !complain) return;
                    editSalaryTasksById(edtData);
                  }}
                />
              )}
            </div>
            <div className="d-flex flex-column align-items-start gap-2">
              <label className={styles.customRadioItem}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="contactChoice1"
                  name="status"
                  value="done"
                  checked={status === "done"}
                  onChange={handleChangeStatus}
                />
                <div className={styles.customRadioItemLabel}>
                  {t("finishTask")}
                </div>
              </label>
              <label className={styles.customRadioItem}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="contactChoice2"
                  name="status"
                  value="dispute"
                  checked={status === "dispute"}
                  onChange={handleChangeStatus}
                />
                <div className={styles.customRadioItemLabel}>
                  {" "}
                  {t("disputeTask")}{" "}
                </div>
              </label>
              <Collapse in={status === "dispute"} timeout="auto">
                <div className="mt-2">
                  <textarea
                    value={complain}
                    className={styles["complain-textarea"]}
                    onChange={handleComplain}
                  />
                  {submit && !complain && (
                    <div className="error">{t("required")}</div>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      )}
      {data.status === "dispute" && (
        <div className={styles.taskType}>
          <div className="d-flex flex-column">
            <div className={styles.heading}> {t("userComplain")} </div>
            <span className={styles.complain}>
              {data?.employee_salary?.user_complain}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
