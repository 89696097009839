import { Link } from "react-router-dom";
import style from "./CompanyItem.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import closeIcon from "../../assets/cl.svg"
import menuIcon from "../../assets/employMenu.svg"
import editIcon from "../../assets/edit-2.svg"
import deleteIcon from "../../assets/Icon (3).svg"
import moneyIcon from "../../assets/money.svg"
import locationIcon from "../../assets/location.svg"
import { EditCompanyComponent } from "..";


type Props = {
  img: string;
  id?: number;
  name?: string;
  profession: string;
  onClick?: () => void;
  managers?: any;
  to: string;
  accountant_worker?: any;
  tarif?: number,
  is_active?: boolean
  tin?:any
  address?:any
};

const CompanyItem = ({
  img,
  name,
  onClick,
  managers,
  tarif,
  id,
  to,
  accountant_worker,
  is_active,
  tin,
  address
}: Props) => {
  const filteredArr = localStorage.getItem("rbcRole") === "1" ? accountant_worker?.slice(0, 3) : managers?.slice(0, 3);
  const array = localStorage.getItem("rbcRole") === "1" ? accountant_worker : managers
  const { t } = useTranslation()
  const [activeMenu, setActiveMenu] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  return (<>
{/* <Link onClick={onClick} className={style.companyItem} to={to}> */}
{(openModal || openDelete) && <div className={openDelete?style.modalDivNone:style.modalDiv}>
      <EditCompanyComponent 
    id={id}
    setOpenModal={setOpenModal}
    openModal={openModal}
    openDelete={openDelete}
    setOpenDelete={setOpenDelete}
   /></div>}
    <div className={style.companyItem}>
      {!is_active && <div className={style.deactivated}>{t('deactivated')}</div>}
      <div className={style.companyHeader}>
        <div className={style.name}>
          <span>{name}</span>
        </div>
        <div className={style.companyHeaderItem}>
          <div className={`${style.tarif} ${tarif === 2 ? style.premiumTarif : ''}`}>{tarif === 1 ? "Basic" : "Premium"}</div>
          <div className={style.employeeItemMenu} onClick={() => { setActiveMenu(!activeMenu) }}>
            {activeMenu && <div className={style.menu}>
              {/* <Link onClick={onClick} to={to}> */}
              <div className={style.menuEditDiv} onClick={() => { setOpenModal(true); setOpenDelete(false)}}>
                <div className={style.menuEditImgDiv}>
                  <img className={style.menuEditImg} src={editIcon} />
                </div>
                <span className={style.editText}>Edit</span>
              </div>
              {/* </Link> */}
              <div className={style.menuDeleteDiv} onClick={() => { setOpenDelete(true) }}>
                <div className={style.menuDeleteImgDiv}>
                  <img className={style.menuDeleteImg} src={deleteIcon} />
                </div>
                <span className={style.deleteText}>Delete</span>
              </div>
            </div>}
            {activeMenu ? <img className={style.employeeItemImg} src={closeIcon} /> : <img className={style.employeeItemImg} src={menuIcon} />}

          </div>
        </div>
      </div>
      <div className={style.companyMain}>
        {img ? (
          <img className={style.image} src={`${img}`} />
        ) : (
          <div className={style.imgContainer}>
            <img className={style.defaultImage} src={"/managerDefaultImg.svg"} />
          </div>
        )}
        <div className={style.companyMainItem}>
          <div className={style.companyMainItemElement}>
            <div className={style.companyMainImgDiv}>
              <img className={style.companyMainImg} src={moneyIcon} />
            </div>
            <span className={style.companyMainItemElementText}>{tin}</span>
          </div>
          <div className={style.companyMainItemElement}>
            <div className={style.companyMainImgDiv}>
              <img className={style.companyMainImg} src={locationIcon} />
            </div>
            <span className={style.companyMainItemElementText}>{`${address}`}</span>
          </div>
        </div>
      </div>

      <div className={`d-flex flex-column ${style.nameProfession}`}>
        {/* <div className={style.name}>
          <span>{name}</span>
        </div> */}

        <div>
          {array.length === 1 ? (
            <div className={style.managersCont}>
              {array?.map((elem: any, ind: number) => {
                return (
                  <div className={`${style.oneManagerCont} w-100`}>
                    {elem.avatar_image ? (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}${elem.avatar_image}`}
                      />
                    ) : (
                      <div className={style.managerDefault}>
                        <img src={"/managerDefaultImg.svg"} />
                      </div>
                    )}
                    {/* <div className={`${style.managerName}`}>
                      {elem?.user?.first_name} {elem?.user?.last_name}
                    </div> */}
                  </div>
                );
              })}
            </div>
          ) : array.length > 1 && array.length < 4 ? (
            <div className={style.managersCont}>
              {array?.map((elem: any, ind: number) => {
                return (
                  <div
                    className={`${style.managerAvatar}`}
                    style={{
                      zIndex: ind,
                      // position: "absolute",
                      marginLeft: ind !== 0 ? `-10px` : "",
                    }}
                  >
                    {elem.avatar_image ? (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}${elem.avatar_image}`}
                      />
                    ) : (
                      <div className={style.managerDefault}>
                        <img src={"/managerDefaultImg.svg"} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={style.managersCont}>
              {filteredArr?.map((elem: any, ind: number) => {
                return (
                  <div
                    className={`${style.managerAvatar}`}
                    style={{
                      zIndex: ind,
                      // position: "absolute",
                      marginLeft: ind !== 0 ? `-10px` : "",
                    }}
                  >
                    <img
                      src={
                        elem.avatar_image
                          ? `${process.env.REACT_APP_API_KEY}${elem.avatar_image}`
                          : "/managerDefaultImg.svg"
                      }
                    />
                  </div>
                );
              })}
              {array.length > 3 && (
                <div className={style.managersCount}>
                  +{array.length - 3}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

    </div>
          {/* </Link> */}
          </>);
};
export default CompanyItem;
