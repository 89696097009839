import { useState, useCallback, useMemo, useEffect } from "react";
import styleProfile from "./EditCompanyComponent.module.scss"
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Params, useNavigate, useParams } from "react-router-dom";
import {
  AddInput,
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  ProfileFileUpload,
} from "..";
import Close from "../SvgCompoents/Close";
import { useGetManagersQuery } from "../../store/services/managers";
import {
  useAddCompaniesMutation,
  useEditCompaniesMutation,
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
} from "../../store/services/companies";
import { useAddImagesMutation } from "../../store/services/photo";
import ChangePhoto from "../SvgCompoents/ChangePhoto";
import { toast } from "react-toastify";
import EditUser from "../SvgCompoents/EditUser";
import DeleteAccountDialog from "../../Dialogs/DeleteAccountDialog";
import closeIcon from "../../assets/cl.svg";
import addMenagerIcon from '../../assets/Rectangle 2 Copy 7.svg';
import localIcon from "../../assets/location.svg";


interface IProps {
  id: any;
  setOpenModal: any;
  openModal: any;
  openDelete: any;
  setOpenDelete: any
}

const EditCompany: React.FC<IProps> = (props) => {
  const { id, setOpenModal, openModal, openDelete, setOpenDelete } = props
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [activeMenagerList, setActiveMenagerList] = useState(false);
  const params: any = useParams();
  const { data: companyData, isLoading }: any = useGetCompanyByIdQuery(id);
  const [accountantList, setAccountantList] = useState<string[]>(companyData?.accountant_worker ? companyData?.accountant_worker?.map((el: any) => el?.id) : []);
  const [addressList, setAddressList] = useState<any>(companyData?.address);
  const [managerList, setManagerList] = useState<string[]>(
    companyData?.managers ? companyData?.managers.map((el: any) => el.id) : []
  );
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0, client_id: companyData?.creator });
  const [addImages, { data }] = useAddImagesMutation();
  const [editCompany, { data: editedCompanyData }] = useEditCompaniesMutation();
  const myId = localStorage.getItem('rbcRole');
  const handleEdit = useCallback(
    () =>
      setTimeout(() => {
        setDisable(false);
      }, 500),
    []
  );
  useEffect(() => {
    setAddressList(companyData?.address);
  }, [companyData?.address]);
  useEffect(() => {
    setAccountantList(companyData?.accountant_worker.map((el: any) => el?.id))
  }, [companyData?.accountant_worker])
  useEffect(() => {
    setManagerList(companyData?.managers.map((el: any) => el.id));
  }, [companyData?.managers]);
  useEffect(() => {
    openDelete &&
      handleOpen()
  }, [openDelete])
  useEffect(() => {
    openModal && handleEdit()
  }, [openModal])


  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
    // addresses: Yup.array().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string;
    managers: number[];
  }
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, []);
  // const handleClose = useCallback(() => {
  //   setOpen(false)
  // }, []);
  const handleClose = useCallback(() => {
    setOpen(false)
    openDelete ? setOpenDelete(false) : setOpenModal(false)
  }, [openModal, openDelete]);
  const handleCloseMenegers = useCallback(() => {
    setActiveMenagerList(false)
  }, [activeMenagerList]);
  const handleAddManager = useCallback(
    (managerId: any) => {
      // const selected = managerList.find((element) => element === managerId)
      // const newArray = selected ? managerList.filter((el) => el !== selected) : [...managerList, managerId];
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );

  const selectedManagers = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < managers?.length; ind++) {
        const el = managers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList]);

  console.log(managerList, "mmmmeeeeennn");


  return isLoading ? (
    <div></div>
  ) : (
    <div className={`w-100`}>

      <div className={styleProfile.formsCont}>
        {/* {activeMenagerList && managers?.length > 0 && <div className={styleProfile.menagerListPopUp}>
          <div className={styleProfile.header}>
            <span className={styleProfile.headerTitle}>Menagers</span>
            <div className={styleProfile.closeButton} onClick={() => { setActiveMenagerList(false) }}><img src={closeIcon} /></div>
          </div>
          <div className={styleProfile.menagerListPopUpMain}>
            {managers?.map((item: any, index: number) => {
              return (
                <div key={index} className={styleProfile.menagerListItem}>
                  <div className={styleProfile.menagerImageDiv}>
                    <img className={styleProfile.menagerImage} src={`${process.env.REACT_APP_API_KEY}${item?.avatar_image}`} />
                  </div>
                  <span className={styleProfile.menagerListItemText}>{`${item?.user?.last_name} ${item?.user?.first_name}`}</span>
                </div>
              )
            })}
          </div>
          <div className={styleProfile.buttonCont}>
            <CustomButtosWithIcon
              title={t('deleteInfoDialogCancelButtonTitle')}
              type={'button'}
              onClick={handleCloseMenegers}
              icon=''
              titleColor="black"
              color="white"
            />
            <CustomButtosWithIcon
              title={t("save")}
              type="submit"
            onClick={handleAddManager}
            />
          </div>
        </div>} */}
        <div className={styleProfile.header}>
          <span className={styleProfile.headerTitle}>{t("editOrganizationInfo")}</span>
          <div className={styleProfile.closeButton} onClick={() => { setOpenModal(false) }}><img src={closeIcon} /></div>
        </div>
        <div className={styleProfile.main}>
          <Formik
            initialValues={{
              name: companyData?.name,
              hvhh: companyData?.hvhh,
              managers: [110],
            }}
            validationSchema={SignupSchema}
            onSubmit={(
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              editCompany({
                ...values,
                id: id,
                managers: managerList.length > 0 ? managerList : [],
                accountant_worker: accountantList?.length > 0 ? accountantList : [],
                address: addressList,
                image: data ? data : companyData.image,
              })
                .then((res: any) => {
                  if (res.data) {
                    toast.success(t('editProfileSuccess'))
                    setDisable(true)
                  }
                })
            }}
          >
            {({ errors, touched }: any) => (
              <Form>
                <DeleteAccountDialog
                  avatar_image={companyData?.image
                    ? `${process.env.REACT_APP_API_KEY}${companyData?.image}`
                    : ''}
                  data={companyData}
                  title={companyData?.is_active ? t('doYouWantDeactivate') : t('doYouWantActivate')}
                  deleteTitle={t("deleteOrganization")}
                  open={open}
                  handleClose={handleClose}
                  handleDelete={() => {
                    editCompany({
                      id: id,
                      is_active: !companyData?.is_active
                    })
                      .then((res: any) => {
                        if (res.data) {
                          toast.success(t('editProfileSuccess'));
                          setOpen(false)
                        }
                      })
                  }}
                />
                <div className={styleProfile.profilePhotos}>
                  {/* <h1 className={styleProfile.myProfile}>Edit company data</h1> */}
                  <span className={styleProfile.avatarTitle}>{t("avatar")}</span>
                  <div
                    className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
                  >
                    <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                      {/* <div className={styleProfile.profilePhotoEdit}>
                    {data ? (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}${data}`}
                        className={styleProfile.profilePhoto}
                      />
                    ) : companyData?.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}${companyData.image}`}
                        className={styleProfile.profilePhoto}
                      />
                    ) : (
                      <div className={styleProfile.imgContainer}>
                        <img src={"/companyDefaultImg.svg"} />
                      </div>
                    )}
                    <label>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e: any) => {
                          const file: any = e?.target?.files[0];
                          if (file) {
                            const formdata = new FormData();
                            formdata.append("file_url", file);
                            addImages(formdata).then((res: any) => {
                              editCompany({
                                id: params?.id,
                                image: res?.data,
                              })
                            });
                          }
                        }}
                      />
                      <div className={styleProfile.changePhotoCont}>
                        <img src={"/camera.svg"} />
                      </div>
                    </label>
                  </div> */}
                      <ProfileFileUpload
                        forCompany={true}
                        avatar_image={companyData?.image
                          ? `${process.env.REACT_APP_API_KEY}${companyData?.image}`
                          : ''}
                        request={(img: string) => {
                          editCompany({
                            id: id,
                            image: img,
                          })
                        }} />
                    </div>
                  </div>
                </div>
                <div className={styleProfile.formCont}>
                  <div className={styleProfile.inputs}>
                    <CustomInput readonly={disable} name="name" title={t("companyName")} classInput={styleProfile.inputeStyle} />
                    <CustomInput readonly={disable} name="hvhh" title={t("HVHH")} classInput={styleProfile.inputeStyle} />
                    <div className={`${styleProfile.withSelected} ${styleProfile.addresses}`}>
                      <AddInput

                        readonly={disable}
                        label={t("addresses")}
                        addAddress={(val: string) => handleAddAddress(val)}
                      />
                      {addressList?.map((el: any, ind: number) => (
                        <div key={ind} className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}>
                          <div className={styleProfile.selectedItem}>
                            <div className={styleProfile.selectedItemIconDiv}><img className={styleProfile.selectedItemIcon} src={localIcon} /></div>
                            <span className={styleProfile.title}>{el.name}</span>
                          </div>

                          {!disable && <span onClick={() => handleDeleteAddress(ind)}>
                            <Close />
                          </span>}
                        </div>
                      ))}
                    </div>
                    {/* <span className={styleProfile.menagerText}>Menagers</span>
                    <div className={styleProfile.menagerList}>
                      <div className={styleProfile.menagerListImagesDiv}>
                      {companyData?.managers?.map((item:any)=>{
                        return (
                          <div className={styleProfile.menagerIconDiv}> <img className={styleProfile.menagerIcon} src={`${process.env.REACT_APP_API_KEY}${item?.avatar_image?item?.avatar_image:""}`} /></div>
                        )
                      })}
                      </div>
                 
                      <div className={styleProfile.addMenagerDiv} onClick={() => { setActiveMenagerList(true) }}><img className={styleProfile.addMenagerIcon} src={addMenagerIcon} /></div>
                    </div> */}
                    {managers?.length > 0 && <div className={`${styleProfile.withSelected} ${styleProfile.addresses}`}>
                      <CustomSelect
                        readonly={disable}
                        label={t("managers")}
                        list={managers?.map((el: any) => ({
                          name: `${el.user.first_name} ${el.user.last_name}`,
                          id: el.id,
                        }))}
                        value={managerList}
                        multiple={true}
                        onChange={handleAddManager}
                      />
                      {selectedManagers?.map((el: any, ind: number) => {
                        return (
                          <div key={ind} className={styleProfile.selected}>
                            {el?.avatar_image
                              ? <img
                                src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                                className={styleProfile.image}
                              />
                              : <div className={styleProfile.selected__imgcont}><img
                                src={"/managerDefaultImg.svg"}
                                className={styleProfile.imageDefault}
                              /></div>}
                            <span className={styleProfile.title}>
                              {el?.user?.first_name} {el?.user?.last_name}
                            </span>
                            {!disable && <span onClick={() => handleDeleteManager(ind)}>
                              <Close />
                            </span>}
                          </div>
                        );
                      })}
                    </div>}
                    {/* {companyData?.accountant_worker?.length > 0 && <div className={`${styleProfile.withSelected} ${styleProfile.withSelectedAccountant}`}>
                  <CustomSelect
                    readonly={true}
                    label={t("accountants")}
                    list={managers?.map((el: any) => ({
                      name: `${el.user.first_name} ${el.user.last_name}`,
                      id: el?.id,
                    }))}
                    value={accountantList}
                    multiple={true}
                    onChange={() => console.log('aaa')}
                  />
                  {companyData?.accountant_worker?.map((el: any, ind: number) => {
                    return (
                      <div key={ind} className={styleProfile.selected}>
                        {el?.avatar_image
                          ? <img
                            src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                            className={styleProfile.image}
                          />
                          : <div><img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          /></div>}
                        <span className={styleProfile.title}>
                          {el?.user?.first_name} {el?.user?.last_name}
                        </span>

                      </div>
                    );
                  })}
                </div>} */}
                  </div>
                </div>
                <div className={styleProfile.buttonDiv}>
                  <CustomButtosWithIcon
                    title={t('deleteInfoDialogCancelButtonTitle')}
                    type={'button'}
                    onClick={handleClose}
                    icon=''
                    titleColor="black"
                    color="white"
                  />
                  <CustomButtosWithIcon
                    title={t("save")}
                    // icon={
                    //   <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     width="20"
                    //     height="20"
                    //     viewBox="0 0 20 20"
                    //     fill="none"
                    //   >
                    //     <path
                    //       d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                    //       fill="#FEFEFE"
                    //     />
                    //   </svg>
                    // }
                    type="submit"
                  // onClick={handleAddManager}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div >
    </div>
  );
};


export default EditCompany;