import icon from "../../assets/3D.svg"
import styles from "./MenuHeader.module.scss"

const MenuHeader = ()=>{
    return(
        <div className={styles.menuHeader}>
            <div className={styles.iconDiv}><img className={styles.imgDiv} src={icon}/></div>
            <div className={styles.titleDiv}>Business</div>
        </div>
    )
}

export default MenuHeader