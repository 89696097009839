import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Delete from '@mui/icons-material/Delete';
import styles from 'react-day-picker/dist/style.css';
import Close from '../SvgCompoents/Close';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#006884',
        // color: theme.palette.common.white,
        fontSize: 16,
        color: "#8B909A",
        fontWeight: 500,
        // lineHeight: 15,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function CustomizedTables({ rows, name, property, handleDelete, sum, withDelete = true, withDiscount = false, excel }: any) {
    const { t } = useTranslation();
    console.log(property, 'propertypropertyproperty');

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {property.map((el: string, ind: number) => {
                            return <StyledTableCell key={ind} sx={{ fontSize: '16px', padding: '16px 8px', position: 'relative' }} align="left">
                                <div className='transform-rotate'>{t(`${el}`)}</div>
                            </StyledTableCell>
                        })}
                        {withDelete && <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px', position: 'relative' }} align="left">
                            <div className='transform-rotate'>{t('delete')}</div>
                        </StyledTableCell>}
                        {excel && <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px', position: 'relative' }} align="left">
                            <div className='transform-rotate'>
                                <a href={`${process.env.REACT_APP_API_KEY}${excel}`} target='_blank' download>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#fff" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#fff" />
                                    </svg>
                                </a>
                            </div>
                        </StyledTableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any, index: number) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{row.name}</StyledTableCell>
                            <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{t(`${row.measurement}`)}</StyledTableCell>
                            <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{row.count}</StyledTableCell>
                            <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{new Intl.NumberFormat(
                                'en-US',
                            ).format(row.price)}</StyledTableCell>
                            {withDiscount && <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{row.discount}</StyledTableCell>}
                            {row.vat > 0 && <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{
                                new Intl.NumberFormat(
                                    'en-US',
                                ).format(row.vat)}

                            </StyledTableCell>}
                            <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px',color:"red"  }} align="left">
                                {new Intl.NumberFormat(
                                    'en-US',
                                ).format(row.sum ? row.sum : row.price * row.count)}
                            </StyledTableCell>
                            {withDelete && <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px'}} align="center">
                                <div className='cursor-pointer' onClick={() => handleDelete(index)}>  <Close /></div>
                            </StyledTableCell>}

                        </StyledTableRow>
                    ))}
                    {rows.length > 0 && <StyledTableRow >
                        <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">{t('total')}</StyledTableCell>
                        {property.map((el: string, index: number) => {
                            return (index > 0 && index < property.length - 1)
                                ? <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left"></StyledTableCell>
                                : <></>
                        })}
                        <StyledTableCell sx={{ fontSize: '16px', padding: '16px 8px' }} align="left">
                            {new Intl.NumberFormat(
                                'en-US',
                            ).format(sum ? sum.toFixed(2) : rows?.reduce((total: any, num: any) => {
                                return total + num.sum
                            }, 0))}
                        </StyledTableCell>

                    </StyledTableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    );
}