import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import Notification from "../firebaseNotifications/Notification";
import { useTranslation } from "react-i18next";
import { deleteToken } from "../firebaseNotifications/firebase";
import { setLogOut } from "../store/slice/authSlice";
import styles from "../App.module.scss";
import Phone from "../Components/SvgCompoents/Phone";

type Props = {
  children: React.ReactNode;
};

const PrivatePage = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const access = localStorage.getItem("rbc-access-token");
  const auth: any = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth?.isLogged) {
      navigate("/", { replace: true });
    }
  }, [auth, access]);
  return auth?.isLogged ? (
    <div>
      <Dialog
        open={
          (!auth?.me?.is_acc_active && auth?.me?.user_role === 1) ||
          (!auth?.me?.employer?.is_acc_active && auth?.me?.user_role === 2)
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <DialogTitle
            className={`text-center ${styles.textActivateProfile}`}
            id="alert-dialog-title"
          >
            {t("textActivateProfile")}
          </DialogTitle>

          <DialogActions>
            <div className="d-flex flex-column w-100 align-items-center">
              <div className={styles.callButton}>
                <Phone />
                <span className={styles.phone}>+374 (94) 40 60 53</span>
              </div>
              <div
                className={styles.logout}
                onClick={() => {
                  deleteToken();
                  window.location.pathname = "/";
                  localStorage.removeItem("rbc-access-token");
                  localStorage.removeItem("rbcRole");
                  localStorage.removeItem("rbcId");
                  dispatch(setLogOut());
                }}
              >
                {t("logout")}
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      <Notification />
      {children}
    </div>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
export default PrivatePage;
