import { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Clients from "../../Components/SvgCompoents/Clients";
import Company from "../../Components/SvgCompoents/Company";
import Dashboard from "../../Components/SvgCompoents/Dashboard";
import Employees from "../../Components/SvgCompoents/Employees";
import Managers from "../../Components/SvgCompoents/Managers";
import Profile from "../../Components/SvgCompoents/Profile";
import AccountantCompanies from "../../Components/SvgCompoents/AccountantCompanies";
import { useAppDispatch, useAppSelector } from "../../store";
import { useGetTasksQuery } from "../../store/services/tasks";
import Application from "../../Components/SvgCompoents/Application";
import { changeUnseenCount } from "../../store/slice/notificationSlice";

export const MenuHooks = (props: any) => {
  const { t } = useTranslation();
  const auth: any = useAppSelector((state: any) => state.auth);

  const accountant = [
    {
      url: "/profile/dashboard",
      title: "dashboard",
      icon: <Dashboard />,
    },
    {
      url: "/profile/clients",
      title: "clients",
      icon: <Clients />,
    },
    {
      url: "/profile/companies",
      title: "companies",
      icon: <Company />,
    },
    {
      url: "/profile",
      title: "profile",
      icon: <Profile />,
    },
    {
      url: "/profile/employees",
      title: "employees",
      icon: <Employees />,
    },
    {
      url: "/profile/applications",
      title: "aplication",
      icon: <Application />,
    },
  ];

  const accountantWorker = [
    {
      url: "/profile/dashboard",
      title: "dashboard",
      icon: <Dashboard />,
    },
    {
      url: "/profile/companies",
      title: "companies",
      icon: <Company />,
    },
    {
      url: "/profile",
      title: "profile",
      icon: <Profile />,
    },
  ];

  const user =
    auth.me !== null && auth.me.accountant
      ? [
          {
            url: "/profile/dashboard",
            title: "dashboard",
            icon: <Dashboard />,
          },
          // {
          //   url: "/profile",
          //   title: "profile",
          //   icon: <Profile />,
          // },
          {
            url: "/profile/managers",
            title: "managers",
            icon: <Managers />,
          },
          {
            url: "/profile/companies",
            title: "companies",
            icon: <Company />,
          },
        ]
      : [
          {
            url: "/profile/dashboard",
            title: "dashboard",
            icon: <Dashboard />,
          },
          // {
          //   url: "/profile",
          //   title: "profile",
          //   icon: <Profile />,
          // },
          {
            url: "/profile/managers",
            title: "managers",
            icon: <Managers />,
          },
          {
            url: "/profile/companies",
            title: "companies",
            icon: <Company />,
          },
          {
            url: "/profile/applications",
            title: "aplication",
            icon: <Application />,
          },
        ];

  const userWorker = [
    {
      url: "/profile/dashboard",
      title: "dashboard",
      icon: <Dashboard />,
    },
    {
      url: "/profile",
      title: "profile",
      icon: <Profile />,
    },
    {
      url: "/profile/companies",
      title: "companies",
      icon: <Company />,
    },
  ];

  const lawyer = [
    {
      url: "/profile/dashboard",
      title: "dashboard",
      icon: <Dashboard />,
    },
    {
      url: "/profile",
      title: "profile",
      icon: <Profile />,
    },
  ];

  const broker = [
    {
      url: "/profile/companies",
      title: "companies",
      icon: <Company />,
    },
    {
      url: "/profile/accountant-companies",
      title: "accountantCompanies",
      icon: <AccountantCompanies />,
    },
  ];

  const brokerWorker = [
    {
      url: "/profile/companies",
      title: "companies",
      icon: <Company />,
    },
    {
      url: "/profile/accountant-companies",
      title: "accountantCompanies",
      icon: <AccountantCompanies />,
    },
  ];

  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { id } = useParams();
  const [showExtendedMenu, setShowExtendedMenu] = useState(
    window.innerWidth <= 1024 ? false : true
  );
  const status =
    auth?.me?.user_role === 3 || auth?.me?.user_role === 4
      ? "answeredByAccountant"
      : "created";
  const {
    data: tasks,
    isLoading,
    refetch,
  } = useGetTasksQuery({
    limit: 100000000000000000,
    status,
  });
  const drawerWidth =
    !showExtendedMenu ||
    (window.location.href.includes("/profile/dashboard/") && id)
      ? "15%"
      : "15%";
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const dispatch = useAppDispatch();
  const role = localStorage.getItem("rbcRole")
    ? localStorage.getItem("rbcRole")
    : `${auth?.me?.user_detail?.user_role}`;

  const handleChangeMenuSize = useCallback(() => {
    setShowExtendedMenu(
      window.location.href.includes("/profile/dashboard/") && id
        ? true
        : !showExtendedMenu
    );
  }, [showExtendedMenu]);
  const handleShowNotification = useCallback(() => {
    setShowNotification(!showNotification);
    dispatch(changeUnseenCount());
  }, [showNotification]);
  const companies = useMemo(() => {
    let countsObj: any = {};
    return tasks?.results
      ?.filter((obj: any, index: number) => {
        return (
          tasks?.results.findIndex((item: any) => {
            if (item?.company?.id === obj?.company?.id) {
              countsObj[item?.company?.name] = countsObj[item?.company?.name]
                ? countsObj[item?.company?.name]
                : 0;
              countsObj[item?.company?.name] =
                +countsObj[item?.company?.name] + 1;
            }
            return item?.company?.id === obj?.company?.id;
          }) === index
        );
      })
      .map((el: any) => ({
        name: el?.company?.name,
        id: el?.company?.id,
        count: countsObj[el?.company?.name],
      }));
  }, [tasks]);
  const currentMenu = useMemo(() => {
    switch (role) {
      case "1":
        return accountant;
      case "2":
        return accountantWorker;
      case "3":
        return user;
      case "4":
        return userWorker;
      case "5":
        return lawyer;
      case "6":
        return broker;
      case "7":
        return brokerWorker;
      default:
        break;
    }
  }, [role, auth.me]);

  useEffect(() => {
    let interval: any;
    window.onfocus = function () {
      interval = setInterval(() => {
        refetch();
      }, 30000);
    };
    window.onblur = function () {
      clearInterval(interval);
    };
    return () => clearInterval(interval);
  }, []);

  const appBarStyle = {
    // width: { md: `calc(100% - ${drawerWidth}px)` },
    // ml: { md: `${drawerWidth}px` },
    width: "40px",
    height: "40px",
    position: "absolute",
    left: "5px",
    top: "-15px",
    display: { md: "none" },
    transition: "all 0.5s ease-out",
    backgroundColor: "white",
  };

  const drawerStyle = {
    display: { xs: "block", md: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
    },
  };

  return {
    role,
    user,
    lawyer,
    broker,
    userWorker,
    accountant,
    mobileOpen,
    drawerStyle,
    appBarStyle,
    currentMenu,
    drawerWidth,
    brokerWorker,
    accountantWorker,
    tasks,
    companies,
    status,
    showExtendedMenu,
    showNotification,
    setShowNotification,
    handleShowNotification,
    handleChangeMenuSize,
    t,
    dispatch,
    navigate,
    container,
    setMobileOpen,
    handleDrawerToggle,
  };
};
