import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, DetailItem, FileDownload } from '../../../Components';
import { useEditFreeTaskByIdMutation, useEditHolidayTasksByIdMutation } from '../../../store/services/tasks';
import { Formik, FormikHelpers, Form } from 'formik';
import { toast } from 'react-toastify';
import Recording from '../../../Components/Recording';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFiles, ITaskPage } from '../../../types';
import { getFileName } from '../../../Utils';
import { useAddImagesMutation } from '../../../store/services/photo';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import moment from 'moment';
interface DataTask {
    data: ITaskPage
}
export default function Free({ data }: DataTask) {
    const { t } = useTranslation();
    const description = t('salaryDescription');
    const navigate = useNavigate();
    const [editFreeTaskById] = useEditFreeTaskByIdMutation();
    const [addImages] = useAddImagesMutation();
    const [submit, setSubmit] = useState<boolean>(false);
    const [savedFiles, setSavedFiles] = useState<IFiles>({});
    const [files, setFiles] = useState<IFiles>({});
    const file = useMemo(() => {
        return getFileName(data?.free_task?.file)
    }, [data?.free_task?.file]);

    const SignupSchema = Yup.object().shape({
        acc_comment: Yup.string()
            .required(t('required'))
    });
    interface Values {
        acc_comment: string
    }
    useEffect(() => {
        if (data?.free_task) {
            const { acc_file } = data?.free_task
            setSavedFiles({ acc_file })
        }
    }, [data])
    const acc_file = useMemo(() => {
        return getFileName(savedFiles?.acc_file)
    }, [savedFiles?.acc_file]);
    const handleDeleteFile = useCallback((name: keyof typeof savedFiles) => {
        if (savedFiles[name]) {
            setSavedFiles((prev: IFiles) => {
                const prevFiles = { ...prev };
                delete prevFiles[name];
                return prevFiles
            })
        } else {
            setFiles((prev: IFiles) => {
                const prevFiles = { ...prev };
                delete prevFiles[name];
                return prevFiles
            })
        }

    }, [savedFiles])
    const handleSetFile = useCallback((data: IFiles) => {
        setFiles({ ...files, ...data })
    }, [files])
    return (
        <div className={`w-100`}>
            <div className={styles.taskType}>
                <div className='d-flex justify-content-between flex-nowrap'>
                    <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
                    <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
                </div>
                <div className='d-flex justify-content-between flex-wrap'>
                    <div>
                        <div className={styles.heading}>{t('detail')} </div>
                        <div className={styles.detailCont}>
                            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
                            {data?.free_task?.comment && <DetailItem name={t('comment')} value={data?.free_task?.comment} />}
                            {data?.free_task?.acc_comment && <DetailItem name={t('accComment')} value={data?.free_task?.acc_comment} />}
                            {data?.created_at && <DetailItem name={t('createdAt')} value={moment(data?.created_at).format('DD-MMMM-YYYY')} />}
                            {data?.free_task?.recording && <div className={styles.application}>
                                <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
                                <Recording src={data?.free_task?.recording} />
                            </div>}
                        </div>
                    </div>
                    {(data?.free_task?.file || data?.free_task?.acc_file) && <div className={styles.application}>
                        <h4 className={styles.subHeading}>{t('file')}</h4>
                        <div className={`d-flex ${styles.cardCont}`}>
                            {data?.free_task?.file && <FileDownload
                                url={`${process.env.REACT_APP_API_KEY}/${data?.free_task.file}`}
                                status={data?.status}
                                title={file}
                            />}
                        </div>
                        {data?.free_task?.acc_file && <><h4 className={styles.subHeading}>{t('accFile')}</h4>
                            <div className={`d-flex ${styles.cardCont}`}>
                                <FileDownload
                                    url={`${process.env.REACT_APP_API_KEY}/${data?.free_task.acc_file}`}
                                    status={data?.status}
                                    title={acc_file}
                                />
                            </div>
                        </>}
                    </div>}
                </div>
            </div>
            {(data.status === "created" || data?.status === 'dispute') && <>
                <div className={styles.taskType}>
                    <Formik
                        initialValues={{
                            acc_comment: data?.free_task?.acc_comment ? data?.free_task?.acc_comment : ''
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(
                            values: Values,
                            { setSubmitting }: FormikHelpers<Values>
                        ) => {
                            const savedData = { ...savedFiles, ...files }
                            if (savedData && savedData.acc_file) {
                                editFreeTaskById({ ...savedData, ...values, id: data?.free_task?.id, task: data?.id, status: 'answeredByAccountant' })
                                    .then((res: any) => {
                                        if (res?.data?.id) {
                                            toast.success('Գործընթացը հաջողությամբ կատարվել է');
                                            navigate('/profile/dashboard')
                                        }
                                    })
                            }
                        }}
                    >
                        {({ errors, touched, values }: any) => (
                            <Form >
                                <div className={styles.taskType}>
                                    <div className={`d-flex justify-content-between align-items-center ${styles.heading}`}>
                                        <span> {t('accountantAttachedFiles')} </span>
                                        {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                                            title={t("answer")}
                                            icon={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                                                        fill="#FEFEFE"
                                                    />
                                                </svg>
                                            }
                                            type="submit"
                                            onClick={() => {
                                                setSubmit(true)

                                            }}
                                        />}
                                    </div>
                                    <div className={`d-flex flex-wrap ${styles.cardsCont}`}>

                                        <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                                            <input type='file' style={{ display: 'none' }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                                                    if (file) {
                                                        const formdata = new FormData();
                                                        formdata.append('file_url', file)
                                                        addImages(formdata)
                                                            .then((res: any) => {
                                                                if (res.data) {
                                                                    handleSetFile({ acc_file: res.data })
                                                                }
                                                            })
                                                    }
                                                }} />
                                            <span className={styles.cardLabel}>{t('document')}</span>
                                            {files?.acc_file
                                                ? <ComponentWithBorder onClick={() => handleDeleteFile('acc_file')}
                                                    img={`${process.env.REACT_APP_API_KEY}${files.acc_file}`} />
                                                : (acc_file
                                                    ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles.acc_file}`}
                                                        status={data?.status}
                                                        title={acc_file}
                                                        deleteItem={() => handleDeleteFile('acc_file')}
                                                    />
                                                    : <div className='d-flex flex-column'>
                                                        <DocumentCard />
                                                        <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                                                        {submit && !files?.acc_file && <div className='error error-text'>{t('required')}</div>}
                                                    </div>)}
                                        </label>
                                    </div>
                                    <CustomInput name='acc_comment' title={t('comment')} className='taskInput' />
                                    {data?.status === "dispute" && <div className='d-flex flex-column mt-3'>
                                        <div className={styles.heading}>{t('userComplain')} </div>
                                        <span className={styles.complain}>{data?.free_task?.user_complain}</span>
                                    </div>}
                                </div>
                            </Form>)}
                    </Formik>
                </div>
            </>}

        </div>
    );
}


