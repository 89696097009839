import styleProfile from "./AddManager.module.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import {
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  ProfileFileUpload,
  RadioButtons,
} from "../../../Components";
import { useAddManagersMutation } from "../../../store/services/managers";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAddImagesMutation } from "../../../store/services/photo";
import ChangePhoto from "../../../Components/SvgCompoents/ChangePhoto";
import { RootState, useAppSelector } from "../../../store";
import { toast } from "react-toastify";
import Close from "../../../Components/SvgCompoents/Close";
import { useGetTasksTypeQuery } from "../../../store/services/tasks";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import closeIcon from "../../../assets/cl.svg";

interface IProps {
  setActiveAddMenager?: any;
  activeAddMenager?: any;
}

const AddEmployees: React.FC<IProps> = (props) => {
  const { setActiveAddMenager, activeAddMenager } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [tasksTypeList, setTasksTypeList] = useState<string[]>([]);
  const [equalPasswords, setEqualPasswords] = useState<boolean>(true);
  const [submited, setSubmited] = useState<boolean>();
  const [avatarImg, setAvatarImg] = useState<string>();
  const [selectFirstFive, setSelectFirstFive] = useState<boolean>(false);
  const { data: tasksType } = useGetTasksTypeQuery({});
  const re = /^[0-9\b]+$/;
  interface Values {
    first_name: string;
    last_name: string;
    phone_number?: string;
    email: string;
  }
  const SignupSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    phone_number: Yup.string()
      .required(t("required"))
      .test("unique", t("phoneNumberError").toString(), (value: any) =>
        /^[0-9\b]+$/.test(value)
      ),
    email: Yup.string().trim().email(t("emailError")).required(t("required")),
  });

  const [addManagerRequest, { isSuccess }] = useAddManagersMutation();
  const [addImages, { data }] = useAddImagesMutation();
  const auth: any = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isSuccess) {
      navigate("/profile/managers");
      toast.success(t("createManagerSuccess"));
    }
  }, [isSuccess]);
  const handleAddTasksType = useCallback(
    (tasksTypeId: any) => {
      setTasksTypeList((prev: any) => {
        const newArray = [...prev];
        newArray.push(tasksTypeId);
        return newArray;
      });
    },
    [tasksTypeList]
  );

  const handleDeleteTasksType = useCallback(
    (id: number) => {
      const copyArray = [...tasksTypeList];
      const newArray = copyArray.filter((el) => +el !== id);
      setTasksTypeList(newArray);
    },
    [tasksTypeList]
  );

  const handleSelectFirstFive = useCallback(() => {
    setSelectFirstFive(!selectFirstFive);
  }, [selectFirstFive]);

  const selectedTasksType = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < tasksTypeList?.length; index++) {
      const element = tasksTypeList[index];
      for (let ind = 0; ind < tasksType?.length; ind++) {
        const el = tasksType[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [tasksTypeList]);
  const handleSetImages = useCallback((img: string) => {
    setAvatarImg(img);
  }, []);
  const handleSubmite = useCallback(() => {
    setSubmited(true);
  }, []);
  return (
    <div className={styleProfile.backgraund}>
      <div className={styleProfile.cont}>
        <div className={styleProfile.formsCont}>
          <div className={styleProfile.header}>
            <span className={styleProfile.headerTitle}>
              {t("addNewMenager")}
            </span>
            <div
              className={styleProfile.closeButton}
              onClick={() => {
                setActiveAddMenager(false);
              }}
            >
              <img src={closeIcon} />
            </div>
          </div>
          <div className={styleProfile.main}>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                if (selectedTasksType?.length > 0 || selectFirstFive) {
                  const selectFirstFiveArray = selectFirstFive
                    ? tasksType
                        .filter((el: any, ind: number) => ind < 6)
                        .map((el: any) => el.id)
                    : [];
                  const selectedType = selectFirstFive
                    ? [...tasksTypeList, ...selectFirstFiveArray]
                    : tasksTypeList;
                  addManagerRequest({
                    ...values,
                    role: 4,
                    legal_type: 1,
                    tin: "",
                    company_name: "",
                    birth_date: new Date(),
                    language: i18n.language === "am" ? "hy" : i18n.language,
                    cover_image: "",
                    avatar_image: avatarImg ? avatarImg : "",
                    task_types: selectedType,
                  });
                }
              }}
            >
              {({ errors, touched }: any) => (
                <Form>
                  <div className={styleProfile.profilePhotos}>
                    <span className={styleProfile.avatarTitle}>
                      {t("avatar")}
                    </span>
                    <div
                      className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
                    >
                      <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                        <div className={styleProfile.avatarIconDiv}>
                          <ProfileFileUpload
                            avatar_image={
                              avatarImg
                                ? `${process.env.REACT_APP_API_KEY}${avatarImg}`
                                : ""
                            }
                            request={handleSetImages}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styleProfile.inputs}>
                    <CustomInput
                      classInput={styleProfile.inputeStyle}
                      name="first_name"
                      title={t("firstName")}
                    />
                    <CustomInput
                      classInput={styleProfile.inputeStyle}
                      name="last_name"
                      title={t("lastName")}
                    />
                    <CustomInput
                      classInput={styleProfile.inputeStyle}
                      name="email"
                      title={t("emailAdress")}
                    />
                    <CustomInput
                      classInput={styleProfile.inputeStyle}
                      name="phone_number"
                      title={t("phoneNumber")}
                    />
                  </div>
                  <div
                    className={`${styleProfile.withSelected} position-relative`}
                  >
                    <RadioButtons
                      title={t("taskTypes")}
                      label={t("workWithEmployee")}
                      selectFirstFive={selectFirstFive}
                      array={tasksType}
                      selectedList={tasksTypeList}
                      onClick={(id: number) => {
                        tasksTypeList?.filter((type) => +type === id)[0]
                          ? handleDeleteTasksType(id)
                          : handleAddTasksType(id);
                      }}
                      handleSelectFirstFive={handleSelectFirstFive}
                    />
                    {submited && selectedTasksType.length === 0 && (
                      <div className="error">{t("required")}</div>
                    )}
                  </div>

                  <div className={styleProfile.buttonDiv}>
                    <CustomButtosWithIcon
                      title={t("deleteInfoDialogCancelButtonTitle")}
                      type={"button"}
                      onClick={() => {
                        setActiveAddMenager(false);
                      }}
                      icon=""
                      titleColor="black"
                      color="white"
                    />
                    <CustomButtosWithIcon
                      type="submit"
                      title={t("save")}
                      // icon={
                      //   <svg
                      //     xmlns="http://www.w3.org/2000/svg"
                      //     width="20"
                      //     height="20"
                      //     viewBox="0 0 20 20"
                      //     fill="none"
                      //   >
                      //     <path
                      //       d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                      //       fill="#FEFEFE"
                      //     />
                      //   </svg>
                      // }
                      onClick={handleSubmite}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {!equalPasswords && (
            <p className="error">Գաղտնաբառն ու կրկնել գաղտնաբառը նույնը չեն</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddEmployees;
