import Box from "@mui/material/Box";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "../Menu.module.scss";
//@ts-ignore
import file from "../../../exchangerates.xml"
import { NotificationItem } from "../../../Components";
import Option from "../../../Components/SvgCompoents/Option";
import { useGetNotificationsQuery } from "../../../store/services/notifications";
import { useAppDispatch, useAppSelector } from "../../../store";
import { changeLastSeen, exchangerates, getMyNotifications, readNotifications } from "../../../store/slice/notificationSlice";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../../assets/cl.svg"
import { MenuHooks } from "../Menu.hook";

interface IProps {
  showNotification?:any;
  setShowNotification?:any;
}

const NotificationSection:React.FC<IProps> = (props) => {
  const{showNotification,setShowNotification}=props
  const [page, setPage] = useState<number>(1);
  const notificationCont = useAppSelector((state) => state.notificationsContainer);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  console.log(showNotification,"11111");
  
  // const { data } = useGetNotificationsQuery({ page, limit: 10 })
  const handleChangePage = useCallback(() => {
    setPage(page + 1);
    dispatch(getMyNotifications({ page: page + 1 }))
  }, [page])

  useEffect(() => {

    dispatch(getMyNotifications({}))
    // dispatch(exchangerates())
  }, []);
  useEffect(() => {
    if (notificationCont?.notifications?.results[0]) {
      dispatch(changeLastSeen(notificationCont?.notifications?.results[0]?.id))
    }
  }, [notificationCont?.notifications?.results]);
console.log(notificationCont?.notifications?.count,"nnnnn");

  return (
    <Box>
      <div className={style.notificationsDiv}>
        <div className={style.notCont}>
          <div className={style.activity}>
            <span className={style.activityTitle}>{t('notification')}</span>
            <span className={style.activityCount}>{notificationCont?.notifications?.count}</span>
          </div>
          <div className={style.activityItem}>
            <span className={style.textStyle}>Mark all as read</span>
            <div className={style.close} onClick={()=>{setShowNotification(false)}}><img className={style.closeImage} src={closeIcon} /></div>
          </div>
        </div>
        <div className={style.notifications}>
          {notificationCont?.notifications?.results?.map((el: any, ind: number) => {
            return <NotificationItem
              key={ind}
              onClick={() => {
                dispatch(readNotifications({ "notifications": el.id }))
                navigate(el.task ? `/profile/dashboard/${el.task}` : '/profile/applications')
              }}
              is_reed={el?.is_reed}
              title={t(`${el?.title}`)}
              avatar={el?.creator?.avatar_image ? `${process.env.REACT_APP_API_KEY}${el?.creator?.avatar_image}` : ''}
              name={(el?.creator?.user_role === 1 || el?.creator?.user_role === 2)
                ? el?.creator?.company_name
                : (el?.creator?.user_role === 4
                  ? `${el?.creator?.user?.first_name} ${el?.creator?.user?.last_name} / ${el?.employer_detail?.first_name} ${el?.employer_detail?.last_name}`
                  : `${el?.creator?.user?.first_name ? el?.creator?.user?.first_name : ''} ${el?.creator?.user?.last_name ? el?.creator?.user?.last_name : ''}`)}
              date={moment(el.created_at).format('DD/MM/YYYY')}
            />
          })}
          {notificationCont?.notifications?.next && <div className={style.seeMore} onClick={handleChangePage}>{t('seeMore')}</div>}

        </div>
      </div>
    </Box>
  );
}


export default NotificationSection;