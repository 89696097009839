import { Link } from "react-router-dom";
import { useState } from "react";
import menuIcon from "../../assets/employMenu.svg";
import closeIcon from "../../assets/cl.svg";
import defautImage from "../../assets/profile-circle.svg";
import editIcon from "../../assets/edit-2.svg";
import deleteIcon from "../../assets/Icon (3).svg";
import smsIcon from "../../assets/sms-notification.svg";
import callIcon from "../../assets/call.svg";
import workIcon from "../../assets/Frame 1000006882.svg";
import style from "./EmployeeItem.module.scss";
import { EditManager } from "../EditMenagerComponent";
import { useGetManagerQuery } from "../../store/services/managers";
import { log } from "console";
import { useTranslation } from "react-i18next";

type Props = {
  img: string;
  name?: string;
  firstName?: string | undefined;
  lastName?: any;
  profession: string;
  onClick?: () => void;
  id?: number;
  to: string;
};

const EmployeeItem = ({
  img,
  name,
  firstName,
  lastName,
  profession,
  onClick,
  id,
  to,
}: Props) => {
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const { data: managerData, isLoading } = useGetManagerQuery({ id });
  console.log(managerData, "hubchufbdbcfbu");
  const task = managerData?.task_types
    ?.map((item: any) => item?.task_type?.name)
    .join(", ");
  console.log(task, "tasktasktasktask");

  const { t } = useTranslation();

  return (
    <>
      {" "}
      {(openModal || openDelete) && (
        <div className={openDelete ? style.modalDivNone : style.modalDiv}>
          <EditManager
            id={managerData?.id}
            setOpenModal={setOpenModal}
            openModal={openModal}
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
          />
        </div>
      )}
      <div className={style.employeeItem}>
        <div className={style.employeeItemHeader}>
          <div className={style.employeeItemProfile}>
            {img ? (
              <img
                className={style.image}
                src={`${process.env.REACT_APP_API_KEY}${img}`}
              />
            ) : (
              <div className={style.imgContainer}>
                <img className={style.defaultImage} src={defautImage} />
              </div>
            )}
            <div className={style.nameDiv}>
              <span className={style.firstName}>{firstName}</span>
              <span className={style.lastName}>{lastName}</span>
              <span className={style.lastName}>{name}</span>
            </div>
          </div>

          <div
            className={style.employeeItemMenu}
            onClick={() => {
              setActiveMenu(!activeMenu);
            }}
          >
            {activeMenu && (
              <div className={style.menu}>
                {/* <Link onClick={onClick} to={to}> */}
                <div
                  className={style.menuEditDiv}
                  onClick={() => {
                    setOpenModal(true);
                    setOpenDelete(false);
                  }}
                >
                  <div className={style.menuEditImgDiv}>
                    <img className={style.menuEditImg} src={editIcon} />
                  </div>
                  <span className={style.editText}>Edit</span>
                </div>
                {/* </Link> */}
                <div
                  className={style.menuDeleteDiv}
                  onClick={() => {
                    setOpenDelete(true);
                  }}
                >
                  <div className={style.menuDeleteImgDiv}>
                    <img className={style.menuDeleteImg} src={deleteIcon} />
                  </div>
                  <span className={style.deleteText}>Delete</span>
                </div>
              </div>
            )}
            {activeMenu ? (
              <img className={style.employeeItemImg} src={closeIcon} />
            ) : (
              <img className={style.employeeItemImg} src={menuIcon} />
            )}
          </div>
        </div>
        <div className={style.infoDiv}>
          <div className={style.infoItem}>
            <div className={style.infoItemImgDiv}>
              <img className={style.infoItemImg} src={smsIcon} />
            </div>
            <span className={style.infoItemText}>
              {managerData?.user?.email}
            </span>
          </div>
          <div className={style.infoItem}>
            <div className={style.infoItemImgDiv}>
              <img className={style.infoItemImg} src={callIcon} />
            </div>
            <span className={style.infoItemText}>
              {managerData?.phone_number}
            </span>
          </div>
          <div className={style.infoItem}>
            <div className={style.infoItemImgDiv}>
              <img className={style.infoItemImg} src={workIcon} />
            </div>
            <span className={style.infoItemText}>{`${task},`}</span>
          </div>
        </div>

        {/* <div className={`d-flex flex-column w-100 ${style.nameProfession}`}>
     
        <div className={style.profession}>{profession}</div>
      </div> */}
      </div>
    </>
  );
};
export default EmployeeItem;

{
  /* <Link
onClick={onClick}
to={to}
>
</Link> */
}
