import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomButtosWithIcon, CustomInput } from '../../Components';
import { useResetPasswordMutation } from '../../store/services/user';
import styles from './ResetPassword.module.scss';
import { Box } from "@mui/material";

interface IProps {
  open: boolean,
  handleOpen: () => void,
  handleClose: () => void
}
export default function ResetPassword({ open, handleOpen, handleClose }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resetPasswordRequest, { isSuccess }] = useResetPasswordMutation();
  const SignupSchema = Yup.object().shape({
    new_password: Yup.string()
      .required(t('required')),
    old_password: Yup.string()
      .required(t('required')),
  });

  interface Values {
    new_password: string,
    old_password: string
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={styles.dialogCont}>
          <DialogTitle id="alert-dialog-title" className={styles.heading}>
            {t("password")}
          </DialogTitle>
          <DialogContent>
            <div className='d-flex flex-column'>
              <Formik
                initialValues={{
                  new_password: '',
                  old_password: ''
                }}
                validationSchema={SignupSchema}
                onSubmit={(
                  values: Values,
                  { setSubmitting }: FormikHelpers<Values>
                ) => {
                  resetPasswordRequest(values)
                    .then((res:any) => {
                      toast.success(t('passwordChangedSuccess'))
                      handleClose()
                    })
                }}
              >
                {({ errors, touched }: any) => (
                  <Form className={styles.formCont}>
                    <div className={styles.inputDiv}>
                      <CustomInput name='old_password' type='password' title={t('oldPassword')} classInput={styles.inputeStyle} className={styles.in}/>
                      <CustomInput name='new_password' type='password' title={t('newPassword')} classInput={styles.inputeStyle} className={styles.in}/>
                    </div>
                    <div className={styles.saveDiv}>
                      <CustomButtosWithIcon
                        title={t('save')}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                        </svg>}
                        type='submit'
                        onClick={() => console.log()} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </>

  );
}


