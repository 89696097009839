const Camera = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#556FF6"/>
<path d="M10.6167 22.2666C10.1083 22.2666 9.63333 22.0916 9.29167 21.7666C8.85833 21.3583 8.65 20.7416 8.725 20.075L9.03333 17.375C9.09167 16.8666 9.4 16.1916 9.75833 15.825L16.6 8.58329C18.3083 6.77496 20.0917 6.72496 21.9 8.43329C23.7083 10.1416 23.7583 11.925 22.05 13.7333L15.2083 20.975C14.8583 21.35 14.2083 21.7 13.7 21.7833L11.0167 22.2416C10.875 22.25 10.75 22.2666 10.6167 22.2666ZM19.275 8.42496C18.6333 8.42496 18.075 8.82496 17.5083 9.42496L10.6667 16.675C10.5 16.85 10.3083 17.2666 10.275 17.5083L9.96667 20.2083C9.93333 20.4833 10 20.7083 10.15 20.85C10.3 20.9916 10.525 21.0416 10.8 21L13.4833 20.5416C13.725 20.5 14.125 20.2833 14.2917 20.1083L21.1333 12.8666C22.1667 11.7666 22.5417 10.75 21.0333 9.33329C20.3667 8.69163 19.7917 8.42496 19.275 8.42496Z" fill="white"/>
<path d="M20.45 15.125C20.4333 15.125 20.4083 15.125 20.3917 15.125C17.7917 14.8666 15.7 12.8916 15.3 10.3083C15.25 9.96663 15.4833 9.64996 15.825 9.59163C16.1667 9.54163 16.4833 9.77496 16.5417 10.1166C16.8583 12.1333 18.4917 13.6833 20.525 13.8833C20.8667 13.9166 21.1167 14.225 21.0833 14.5666C21.0417 14.8833 20.7667 15.125 20.45 15.125Z" fill="white"/>
<path d="M23.5 24.9583H8.5C8.15833 24.9583 7.875 24.675 7.875 24.3333C7.875 23.9916 8.15833 23.7083 8.5 23.7083H23.5C23.8417 23.7083 24.125 23.9916 24.125 24.3333C24.125 24.675 23.8417 24.9583 23.5 24.9583Z" fill="white"/>
</svg>
{/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8223 1.73436V5.99993C19.8223 6.21205 19.9065 6.41548 20.0565 6.56547C20.2065 6.71546 20.4099 6.79972 20.6221 6.79972C20.8342 6.79972 21.0376 6.71546 21.1876 6.56547C21.3376 6.41548 21.4219 6.21205 21.4219 5.99993V1.73436C21.4219 1.52225 21.3376 1.31881 21.1876 1.16882C21.0376 1.01883 20.8342 0.93457 20.6221 0.93457C20.4099 0.93457 20.2065 1.01883 20.0565 1.16882C19.9065 1.31881 19.8223 1.52225 19.8223 1.73436Z" fill="#006884" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4897 4.66697H22.7553C22.9674 4.66697 23.1709 4.58271 23.3208 4.43272C23.4708 4.28273 23.5551 4.07929 23.5551 3.86718C23.5551 3.65506 23.4708 3.45163 23.3208 3.30164C23.1709 3.15165 22.9674 3.06738 22.7553 3.06738H18.4897C18.2776 3.06738 18.0742 3.15165 17.9242 3.30164C17.7742 3.45163 17.6899 3.65506 17.6899 3.86718C17.6899 4.07929 17.7742 4.28273 17.9242 4.43272C18.0742 4.58271 18.2776 4.66697 18.4897 4.66697Z" fill="#006884" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8809 5.72907C22.839 5.73211 22.7971 5.73353 22.7551 5.73334H22.4885V5.99994C22.4885 6.49488 22.2919 6.96955 21.9419 7.31953C21.5919 7.66951 21.1172 7.86612 20.6223 7.86612C20.1273 7.86612 19.6527 7.66951 19.3027 7.31953C18.9527 6.96955 18.7561 6.49488 18.7561 5.99994V5.73334H18.4895C18.1463 5.73334 17.8097 5.63869 17.5168 5.45982C17.2239 5.28094 16.986 5.02475 16.8293 4.71943C16.6726 4.41411 16.6031 4.07148 16.6284 3.72922C16.6538 3.38695 16.7731 3.05831 16.9731 2.77943L16.8334 2.49897C16.6784 2.1891 16.4401 1.92851 16.1454 1.74639C15.8506 1.56426 15.511 1.46779 15.1645 1.46777H9.01781C8.67132 1.46779 8.33168 1.56426 8.03693 1.74639C7.74217 1.92851 7.50394 2.1891 7.34891 2.49897L6.33903 4.51979C6.31664 4.56394 6.28248 4.60105 6.24033 4.62701C6.19818 4.65298 6.14967 4.6668 6.10016 4.66695H3.56002C2.78225 4.66695 2.03634 4.97591 1.48637 5.52588C0.936409 6.07584 0.627441 6.82176 0.627441 7.59952V18.2634C0.627441 19.0408 0.936695 19.7873 1.48589 20.3376C2.03638 20.887 2.78227 21.1957 3.56002 21.196H20.6223C21.3997 21.196 22.1462 20.8868 22.6964 20.3376C23.2458 19.7871 23.5545 19.0412 23.5549 18.2634V7.59952C23.5549 6.88824 23.3021 6.23668 22.8809 5.72907ZM12.0912 7.33293C9.29507 7.33293 7.02579 9.60221 7.02579 12.3983C7.02579 15.1944 9.29507 17.4636 12.0912 17.4636C14.8872 17.4636 17.1565 15.1944 17.1565 12.3983C17.1565 9.60221 14.8872 7.33293 12.0912 7.33293ZM12.0912 8.93251C12.9954 8.95495 13.8552 9.32994 14.4868 9.97745C15.1185 10.625 15.472 11.4937 15.472 12.3983C15.472 13.3029 15.1185 14.1716 14.4868 14.8191C13.8552 15.4666 12.9954 15.8416 12.0912 15.8641C11.1869 15.8416 10.3271 15.4666 9.69549 14.8191C9.06385 14.1716 8.71029 13.3029 8.71029 12.3983C8.71029 11.4937 9.06385 10.625 9.69549 9.97745C10.3271 9.32994 11.1869 8.95495 12.0912 8.93251Z" fill="#006884" />
</svg> */}
export default Camera