import React from 'react';
import style from './LeftMenuIcon.module.scss'
import { fileIcon } from '../../Utils';

const LeftMenuIcon = ({showOnlyIcon}:{showOnlyIcon:boolean}) => {
    return (<></>
//     <div style={{left:showOnlyIcon?45:259}} className={style.cont}>
//        {showOnlyIcon? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//             <path d="M7.5001 17.2253C7.65843 17.2253 7.81676 17.1669 7.94176 17.0419L13.3751 11.6086C14.2584 10.7253 14.2584 9.27526 13.3751 8.39193L7.94176 2.95859C7.7001 2.71693 7.3001 2.71693 7.05843 2.95859C6.81676 3.20026 6.81676 3.60026 7.05843 3.84193L12.4918 9.27526C12.8918 9.67526 12.8918 10.3253 12.4918 10.7253L7.05843 16.1586C6.81676 16.4003 6.81676 16.8003 7.05843 17.0419C7.18343 17.1586 7.34176 17.2253 7.5001 17.2253Z" fill="white" />
//         </svg>:<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
// <path d="M12.4999 17.2253C12.3416 17.2253 12.1832 17.1669 12.0582 17.0419L6.6249 11.6086C5.74157 10.7253 5.74157 9.27526 6.6249 8.39193L12.0582 2.95859C12.2999 2.71693 12.6999 2.71693 12.9416 2.95859C13.1832 3.20026 13.1832 3.60026 12.9416 3.84193L7.50824 9.27526C7.10824 9.67526 7.10824 10.3253 7.50824 10.7253L12.9416 16.1586C13.1832 16.4003 13.1832 16.8003 12.9416 17.0419C12.8166 17.1586 12.6582 17.2253 12.4999 17.2253Z" fill="white"/>
// </svg>}
//     </div>
    )
}

export default LeftMenuIcon;