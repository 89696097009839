import { Checkbox, FormControlLabel, FormGroup, styled } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RadioButtons.module.scss";
interface IProps {
  label?: string;
  array: any;
  selectFirstFive: boolean;
  readonly?: boolean;
  selectedList: any;
  title: string;
  onClick: (reg: number) => void;
  handleSelectFirstFive: () => void;
}
const RadioButtons = ({
  label,
  selectedList,
  title,
  array,
  selectFirstFive,
  readonly = false,
  onClick,
  handleSelectFirstFive,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.titleDiv}>
        <span className={styles.title}>{title} </span>
      </div>
      <FormGroup className={styles.formGrup}>
        <FormControlLabel
          className={styles.formLabel}
          control={
            <Checkbox
              className={styles.chak}
              sx={{
                width: "50px",
                height: "50px",
                //   color: 'blue',
                "&.Mui-checked": {
                  color: "#2DC8A8",
                },
              }}
            />
          }
          label={label}
          checked={selectFirstFive}
          onChange={handleSelectFirstFive}
          disabled={readonly}
        />
        {array?.length > 0 &&
          array?.map((el: any, ind: number) => {
            return (
              ind > 5 && (
                <FormControlLabel
                  className={styles.formLabel}
                  control={
                    <Checkbox
                      className={styles.chak}
                      sx={{
                        width: "50px",
                        height: "50px",
                        //   color: 'blue',
                        "&.Mui-checked": {
                          color: "#2DC8A8",
                        },
                      }}
                    />
                  }
                  label={t(`${el?.name}`)}
                  key={ind}
                  checked={
                    !!selectedList?.filter((type: string) => +type === el.id)[0]
                  }
                  onChange={() => {
                    onClick(el.id);
                  }}
                  disabled={readonly}
                />
              )
            );
          })}
      </FormGroup>
    </>
  );
};
export default RadioButtons;
