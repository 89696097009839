import FormControl from '@mui/material/FormControl';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import styles from './DashbordSelect.module.scss';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IFilter } from '../../types';

interface IProps {
  label: string,
  list: any,
  onChange: (val: string) => void,
  value?: any,
  multiple?: boolean,
  showValue?: boolean,
  readonly?: boolean
}

const DashbordSelect = ({ readonly = false, ...props }: IProps) => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    // '& .Mui-selected': {
    //   backgroundColor: 'rgba(25, 118, 210, 0.08)'
    // },
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      height: 24,
      borderRadius: 8,
      position: 'relative',
      // border: '1px solid #97BCC7',
      fontSize: 16,
      padding: '4px',
      // transition: theme.transitions.create(['border-color', 'box-shadow']),
      // '&:focus': {
      //   // borderRadius: 8,
      //   borderColor: '#006884',
      //   boxShadow: 'none',
      // },
    },
  }));
  const { t } = useTranslation();
  const handleChange = (val: SelectChangeEvent) => {
    props.onChange(val.target.value)
  }
  // console.log(props.value, 'props.valueprops.value props?.showValue', props?.showValue);

  return (
    <label style={{width:"100%"}}>
      <div className={styles.label}>{props.label}</div>
      <FormControl sx={{ maxWidth: 501, width: '100%', height: 50}} variant="standard">
        <Select
          disabled={readonly}
          multiple={props.multiple}
          value={props.value}
          input={<BootstrapInput />}
          onChange={handleChange}
          renderValue={(p: any) => {
            console.log(p,'p?.length');
            return p? props?.list?.filter((el: any) => el.id === p)[0]?.name : t('all')
            
          }}
          IconComponent={(iconProps) => (
            <div {...iconProps} className={styles.selectArow}>
              {iconProps.open ? (
                <KeyboardArrowUpIcon style={{ fontSize: '24px' }} />
              ) : (
                <KeyboardArrowDownIcon style={{ fontSize: '24px' }} />
              )}
            </div>
          )}
          // sx={{ maxWidth: 501, width: '100%', marginBottom: 24, height: 50 }}
      
        >
          
        
          <MenuItem value={0}>{t('all')}</MenuItem>
          {props.list?.map((el: { id: number, name: string }) => {
            return <MenuItem key={el.id} value={el.id}>{el?.name}</MenuItem>
          })}
          
        </Select>
      </FormControl>
    </label>
  );
};
export default DashbordSelect;