import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../App.module.scss';
import { CustomButton, ProfessionCard } from '../../Components';
import User from '../../Components/SvgCompoents/User';
import Accountant from '../../Components/SvgCompoents/Accountant';
import Lawyer from '../../Components/SvgCompoents/Lawyer';
import Broker from '../../Components/SvgCompoents/Broker';
import RegisSvg from '../../Components/SvgCompoents/RegisSvg';
import { useTranslation } from 'react-i18next';

export default function Registration() {
  const [active, setAtcive] = useState<{ title: string, id: number }>({ title: 'accountant', id: 1 });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = useCallback(({ id, name, title }: { id: number, name: string, title: string }) => {
    setAtcive({ title, id })
    localStorage.setItem('rbcRole', `${id}`)
  }, [])

  return (
    <div className={`d-flex  justify-content-center align-items-center ${styles.cont}`}>
        <div className={styles.RegisSvg}>
          <RegisSvg />
        </div>
        <div className={`${styles.formCont} ${styles.chooseRole}`}>
          <div className={`${styles.heading} d-none d-md-flex`}>{t('signInAs')}</div>
          <div className={`${styles.subHeading} d-none d-md-flex`}>
            <span>{t('subTitle')}</span>
            <span onClick={() => { navigate("/"); }}>
              {t('signInUp')}
            </span>
          </div>
          {/* <h4 className={styles.title}>{t('selectYourProfileType')}</h4> */}
          <div className='d-flex flex-column align-items-center gap-3 '>
            <span className={styles.subTitle}>{t('profileType')}</span>
            <div className='d-flex justify-content-center flex-wrap gap-3'>
              <ProfessionCard icon={<Accountant />}
                title={t('accountant')}
                active={active.title === 'accountant'}
                onClick={() => handleClick({ title: 'accountant', id: 1, name: 'ACCT' })}
              />
              <ProfessionCard
                icon={<Lawyer />}
                title={t('lawyer')}
                active={active.title === 'lawyer'}
                onClick={() => handleClick({ title: 'lawyer', id: 5, name: 'LWR' })}
              />
              <ProfessionCard
                icon={<User />}
                title={t('user')}
                active={active.title === 'user'}
                onClick={() => handleClick({ title: 'user', id: 3, name: 'USR' })}
              />
              <ProfessionCard
                icon={<Broker />}
                title={t('broker')}
                active={active.title === 'broker'}
                onClick={() => handleClick({ title: 'broker', id: 6, name: 'BKR' })}
              />
            </div>
          </div>
          <div className={styles.regisButton}>
            <CustomButton
              title={t('select')}
              type='button'
              onClick={() => navigate(`/registration/${active.title}`)}
            />
            <div className={styles.bottomText}>{t('agreeLabel')} <a
              target='_blank'
              href='https://docs.google.com/document/d/1CSZdcd_Gupop2CeWNts7-_kX375uw3_40hjLLqRb1OY/edit?usp=drive_link'
              className={styles.signUp}>
              {t('terms')}
            </a>
            </div>
          </div>
        </div>
      </div>
  );
}


