import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CustomButton,
  CustomButtosWithIcon,
  CustomSelect,
} from "../Components";
import { useGetCompaniesQuery } from "../store/services/companies";
import { useAppDispatch, useAppSelector } from "../store";
import Createtask, { createTask } from "../store/slice/createtask";
import { Button } from "@mui/material";
import { useGetTasksTypeQuery } from "../store/services/tasks";
import styles from "./createTask.module.scss";
import "../App.module.scss";
import { ICompanyItem } from "../types";
import closeIcon from "../assets/cl.svg";
import {
  Bonus,
  CreateVacation,
  Dismissal,
  EstimateTask,
  Free,
  Invoice,
  LadingBill,
  ProvisionAllowance,
} from "../Pages";
import CreateTasks from "../Pages/User/CreateTask";
import ChangeProvisionAllowance from "../Pages/User/CreateTask/ChangeSalaryPosition";

interface IProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  setShowCreateVacation?: any;
  showCreateVacation?: any;
  showCreateTask?: any;
  setShowCreateTask?: any;
  createProvisionAllowance?: any;
  setCreateProvisionAllowance?: any;
  createDismissal?: any;
  setCreateDismissal?: any;
  changeSalaryPosition?: any;
  setChangeSalaryPosition?: any;
  createBonus?: any;
  setCreateBonus?: any;
  landingBill?: any;
  setLandingBill?: any;
  estimateTask?: any;
  setEstimateTask?: any;
  createInvoice?: any;
  setCreateInvoice?: any;
  free?: any;
  setFree?: any;
}
export default function CreateTask({
  open,
  handleOpen,
  handleClose,
  showCreateVacation,
  setShowCreateVacation,
  showCreateTask,
  setShowCreateTask,
  createProvisionAllowance,
  setCreateProvisionAllowance,
  createDismissal,
  setCreateDismissal,
  changeSalaryPosition,
  setChangeSalaryPosition,
  createBonus,
  setCreateBonus,
  landingBill,
  setLandingBill,
  estimateTask,
  setEstimateTask,
  createInvoice,
  setCreateInvoice,
  free,
  setFree,
}: IProps) {
  const { t } = useTranslation();
  const myTaskTypes = useAppSelector((state: any) =>
    state?.auth?.me?.task_types?.map((el: any) => el?.task_type)
  );
  const { data: companies } = useGetCompaniesQuery({
    limit: 100000,
    offset: 0,
  });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  const taskData = useAppSelector((state: any) => state.createTask.taskData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const handleSelectCompany = useCallback(
    (data: any) => {
      localStorage.setItem("cmpId", data);
      dispatch(createTask({ ...taskData, company_id: data }));
    },
    [taskData]
  );
  const handleSelectTaskType = useCallback(
    (data: any) => {
      localStorage.setItem("taskTypeId", data);
      const company_id = taskData?.company_id
        ? taskData?.company_id
        : companies?.length === 1
        ? companies[0]?.id
        : "";
      dispatch(createTask({ ...taskData, task_type_id: data, company_id }));
      if (!taskData?.company_id && companies?.length === 1) {
        localStorage.setItem("cmpId", companies[0]?.id);
      }
    },
    [taskData, companies]
  );
  const userRole = localStorage.getItem("rbcRole");
  useEffect(() => {
    dispatch(createTask({ task_type_id: "", company_id: "" }));
  }, []);
  return (
    <>
      <Dialog
        open={
          showCreateTask ||
          showCreateVacation ||
          createInvoice ||
          createProvisionAllowance ||
          createDismissal ||
          changeSalaryPosition ||
          createBonus ||
          landingBill ||
          estimateTask ||
          free
        }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          {showCreateTask && (
            <CreateTasks setShowCreateTask={setShowCreateTask} />
          )}
          {showCreateVacation && (
            <CreateVacation setShowCreateVacation={setShowCreateVacation} />
          )}
          {createInvoice && <Invoice setCreateInvoice={setCreateInvoice} />}
          {createProvisionAllowance && (
            <ProvisionAllowance
              setCreateProvisionAllowance={setCreateProvisionAllowance}
            />
          )}
          {createDismissal && (
            <Dismissal setCreateDismissal={setCreateDismissal} />
          )}
          {changeSalaryPosition && (
            <ChangeProvisionAllowance
              setChangeSalaryPosition={setChangeSalaryPosition}
            />
          )}
          {createBonus && <Bonus setCreateBonus={setCreateBonus} />}
          {landingBill && <LadingBill setLandingBill={setLandingBill} />}
          {estimateTask && <EstimateTask setEstimateTask={setEstimateTask} />}
          {free && <Free setFree={setFree} />}
        </>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles["dialog-content"]}>
          <div className={styles.header}>
            <span className={styles.headerTitle}>{t("createTasks")}</span>
            <div className={styles.closeButton} onClick={handleClose}>
              <img src={closeIcon} alt="Close" />
            </div>
          </div>
          {/* <DialogTitle className={styles["heading"]} id="alert-dialog-title">{t("createTasks")}</DialogTitle> */}
          <div className={styles.main}>
            <DialogContent>
              <div className={styles.dCont}>
                <CustomSelect
                  label={t("companies")}
                  list={companies?.filter((el: ICompanyItem) => el.is_active)}
                  value={
                    taskData?.company_id
                      ? taskData?.company_id
                      : companies?.length === 1
                      ? companies[0]?.id
                      : ""
                  }
                  showValue={true}
                  onChange={(val: string) => handleSelectCompany(val)}
                />
                <CustomSelect
                  label={t("taskTypes")}
                  list={
                    userRole === "4"
                      ? myTaskTypes?.map((el: any) => ({
                          id: el.id,
                          name: t(`${el.name}`),
                        }))
                      : task_types?.map((el: any) => ({
                          id: el.id,
                          name: t(`${el.name}`),
                        }))
                  }
                  value={taskData?.task_type_id}
                  showValue={true}
                  onChange={(val: string) => handleSelectTaskType(val)}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <div className={styles.buttonDiv}>
                <CustomButtosWithIcon
                  title={t("deleteInfoDialogCancelButtonTitle")}
                  type={"button"}
                  onClick={handleClose}
                  icon=""
                  titleColor="black"
                  color="white"
                />
                <CustomButton
                  onClick={handleClose}
                  title={t("create")}
                  type="button"
                />
              </div>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  );
}
