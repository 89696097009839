const Close = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0005 21.9999C17.5005 21.9999 22.0005 17.4999 22.0005 11.9999C22.0005 6.49994 17.5005 1.99994 12.0005 1.99994C6.50049 1.99994 2.00049 6.49994 2.00049 11.9999C2.00049 17.4999 6.50049 21.9999 12.0005 21.9999Z" stroke="#EE1D52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16992 14.8299L14.8299 9.16992" stroke="#EE1D52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.8299 14.8299L9.16992 9.16992" stroke="#EE1D52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
{/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_417_4967)">
    <path d="M12.2005 3.80714C11.9405 3.54714 11.5205 3.54714 11.2605 3.80714L8.00047 7.06047L4.74047 3.80047C4.48047 3.54047 4.06047 3.54047 3.80047 3.80047C3.54047 4.06047 3.54047 4.48047 3.80047 4.74047L7.06047 8.00047L3.80047 11.2605C3.54047 11.5205 3.54047 11.9405 3.80047 12.2005C4.06047 12.4605 4.48047 12.4605 4.74047 12.2005L8.00047 8.94047L11.2605 12.2005C11.5205 12.4605 11.9405 12.4605 12.2005 12.2005C12.4605 11.9405 12.4605 11.5205 12.2005 11.2605L8.94047 8.00047L12.2005 4.74047C12.4538 4.48714 12.4538 4.06047 12.2005 3.80714Z" fill="#EE1D52" />
  </g>
  <defs>
    <clipPath id="clip0_417_4967">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</svg> */}
export default Close