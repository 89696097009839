import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./TaskPage.module.scss";
import { DetailItem, FileDownload } from "../../../Components";
import Recording from "../../../Components/Recording";
import { ITaskPage } from "../../../types";
import { getFileName } from "../../../Utils";
import { useEditHolidayTasksByIdMutation } from "../../../store/services/tasks";
import DeleteTaskDialog from "../../../Dialogs/DeleteTaskDialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../store";
interface DataTask {
  data: ITaskPage;
}
export default function Holiday({ data }: DataTask) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const description = t("salaryDescription");
  const navigate = useNavigate();
  const [editHolidayTasksById, { isSuccess }] =
    useEditHolidayTasksByIdMutation();
  const application = useMemo(() => {
    return getFileName(data?.employee_holiday?.application);
  }, [data?.employee_holiday?.application]);
  const handleOpenDeleteTask = useCallback(() => {
    setOpen(true);
  }, []);
  const handleCloseDeleteTask = useCallback(() => {
    setOpen(false);
  }, []);
  const deleteTask = () => {
    editHolidayTasksById({ id: data?.employee_holiday?.id, status: "deleted" });
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(t("taskHasBeenDeleted"));
      handleCloseDeleteTask();
      navigate("/profile/dashboard");
    }
  }, [isSuccess]);

  return (
    <div className={`w-100`}>
      <DeleteTaskDialog
        open={open}
        handleClose={handleCloseDeleteTask}
        handleDelete={deleteTask}
      />
      <div className={styles.taskType}>
        <div className="d-flex justify-content-between flex-nowrap">
          <div className={`${styles.taskTypeHeading}`}>
            {t(`${data?.task_type?.name}`)}{" "}
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className={`${styles.taskStatus} ${styles[data?.status]}`}>
              {t(`${data?.status}`)}
            </div>
            {data?.status === "created" && (
              <div
                onClick={handleOpenDeleteTask}
                className={`${styles.taskStatus} ${styles.deleteTask}`}
              >
                {t("delete")} <DeleteIcon />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <div className={styles.heading}>{t("detail")} </div>
            <div className={styles.detailCont}>
              {data?.task_name && (
                <DetailItem name={t("taskName")} value={data?.task_name} />
              )}
              {data?.employee_holiday?.recording && (
                <div className={styles.application}>
                  <h4 className={styles.subHeading}>{t("voiceMessage")}</h4>
                  <Recording src={data?.employee_holiday?.recording} />
                </div>
              )}
            </div>
          </div>
          {data?.employee_holiday?.application && (
            <div className={styles.application}>
              <h4 className={styles.subHeading}>{t("accost")}</h4>
              <div className={`d-flex ${styles.cardCont}`}>
                <FileDownload
                  url={`${process.env.REACT_APP_API_KEY}/${data?.employee_holiday.application}`}
                  status={data?.status}
                  title={application}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {data.status === "done" && (
        <div className={styles.taskType}>
          <>
            <div className={styles.heading}> {t("accountantSalary")} </div>
            {data?.employee_holiday && (
              <div>
                {description.replace(
                  "<<X<<",
                  new Intl.NumberFormat("en-US").format(
                    data?.employee_holiday?.salary
                  )
                )}
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}
