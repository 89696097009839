import { useState, useCallback, useMemo, useEffect } from "react";
import styleProfile from "./AddCompany.module.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AddInput, CustomButtosWithIcon, CustomInput, CustomSelect, ProfileFileUpload } from '../../../Components';
import Close from '../../../Components/SvgCompoents/Close';
import { useGetManagersQuery } from '../../../store/services/managers';
import { useAddCompaniesMutation } from '../../../store/services/companies';
import { useAddImagesMutation } from '../../../store/services/photo';
import ChangePhoto from '../../../Components/SvgCompoents/ChangePhoto';
import { toast } from 'react-toastify';
import closeIcon from "../../../assets/cl.svg"
import addMenagerIcon from '../../../assets/Rectangle 2 Copy 7.svg'
import localIcon from "../../../assets/location.svg";

interface IProps {
  setActiveAddMenager?: any;
  activeAddMenager?: any;
}

const AddCompany: React.FC<IProps> = (props) => {
  const { setActiveAddMenager, activeAddMenager } = props
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState<any>([]);
  const [managerList, setManagerList] = useState<string[]>([]);
  const [avatarImg, setAvatarImg] = useState<string>();
  const [submited, setSubmit] = useState<boolean>(false);
  const [activeMenagerList, setActiveMenagerList] = useState(false);
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0 });
  const [addCompaniesRequest, { data: companies, isSuccess }] =
    useAddCompaniesMutation();

  useEffect(() => {
    if (isSuccess) toast.success(t("createCompanySuccess"));
  }, [isSuccess]);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string,
    managers?: number[]
  }
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleAddManager = useCallback(
    (managerId: any) => {
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleSubmit = useCallback(() => {
    setSubmit(true);
  },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );
  const handleSetImages = useCallback((img: string) => {
    setAvatarImg(img)
  }, [])

  const handleCloseMenegers = useCallback(() => {
    setActiveMenagerList(false)
  }, [activeMenagerList]);

  const selectedManagers = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < managers?.length; ind++) {
        const el = managers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList]);

  const [addImages, { data }] = useAddImagesMutation();

  return (
    <div className={styleProfile.backgraund}>
      <div className={styleProfile.cont}>
        {/* {activeMenagerList && managers?.length > 0 && <div className={styleProfile.menagerListPopUp}>
          <div className={styleProfile.header}>
            <span className={styleProfile.headerTitle}>Menagers</span>
            <div className={styleProfile.closeButton} onClick={() => { setActiveMenagerList(false) }}><img src={closeIcon} /></div>
          </div>
          <div className={styleProfile.menagerListPopUpMain}>
            {managers?.map((item: any, index: number) => {
              return (
                <div key={index} className={styleProfile.menagerListItem}>
                  <div className={styleProfile.menagerImageDiv}>
                    <img className={styleProfile.menagerImage} src={`${process.env.REACT_APP_API_KEY}${item?.avatar_image}`} />
                  </div>
                  <span className={styleProfile.menagerListItemText}>{`${item?.user?.last_name} ${item?.user?.first_name}`}</span>
                </div>
              )
            })}
          </div>
          <div className={styleProfile.buttonCont}>
            <CustomButtosWithIcon
              title={t('deleteInfoDialogCancelButtonTitle')}
              type={'button'}
              onClick={handleCloseMenegers}
              icon=''
              titleColor="black"
              color="white"
            />
            <CustomButtosWithIcon
              title={t("save")}
              type="submit"
            // onClick={handleAddManager}
            />
          </div>
        </div>} */}
        <div className={styleProfile.formsCont}>

          <div className={styleProfile.header}>
            <span className={styleProfile.headerTitle}>{t("addNewOrganization")}</span>
            <div className={styleProfile.closeButton} onClick={() => { setActiveAddMenager(false) }}><img src={closeIcon} /></div>
          </div>
          <div className={styleProfile.main}>
            <Formik
              initialValues={{
                name: '',
                hvhh: '',
              }}
              validationSchema={SignupSchema}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                if (addressList.length > 0) {
                  addCompaniesRequest({
                    ...values,
                    managers: managerList.length > 0 ? managerList : [],
                    addresses: addressList,
                    image: avatarImg ? avatarImg : "",
                    tarif: 1
                  })
                    .then((res: any) => {
                      if (res.data.id) {
                        navigate('/profile/companies')
                      }
                    })
                }
              }}
            >
              {({ errors, touched }: any) => (
                <Form>
                  <div className={styleProfile.profilePhotos}>
                    <span className={styleProfile.avatarTitle}>{t("avatar")}</span>
                    {/* <h1 className={styleProfile.myProfile}>{t("addCompany")}</h1> */}
                    <div className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}>
                      <div className={styleProfile.avatarIconDiv}>
                        <ProfileFileUpload
                          forCompany={true}
                          avatar_image={avatarImg ? `${process.env.REACT_APP_API_KEY}${avatarImg}` : ''}
                          request={handleSetImages} />

                      </div>
                    </div>
                  </div>

                  <div className={styleProfile.inputs}>
                    <CustomInput name="name" title={t("companyName")} classInput={styleProfile.inputeStyle} />
                    <CustomInput name="hvhh" title={t("HVHH")} classInput={styleProfile.inputeStyle}/>
                    <div className={`${styleProfile.withSelected} ${styleProfile.addresses}`}>
                      <AddInput
                        label={t("addresses")}
                        addAddress={(val: string) => handleAddAddress(val)}
                      />
                      {submited && addressList.length === 0 && <div className='error'>{t('required')}</div>}
                      {addressList?.map((el: any, ind: number) => (
                        <div key={ind} className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}>
                          <div className={styleProfile.selectedItem}>
                            <div className={styleProfile.selectedItemIconDiv}><img className={styleProfile.selectedItemIcon} src={localIcon} /></div>
                            <span className={styleProfile.title}>{el.name}</span>
                          </div>
                          <span onClick={() => handleDeleteAddress(ind)}>
                            <Close />
                          </span>
                        </div>
                      ))}
                    </div>
                    {managers?.length > 0 && <div className={`${styleProfile.withSelected} ${styleProfile.addresses}`}>
                  <CustomSelect
                    label={t("managers")}
                    list={managers?.map((el: any) => ({
                      name: `${el.user.first_name} ${el.user.last_name}`,
                      id: el.id,
                    }))}
                    value={managerList}
                    multiple={true}
                    onChange={handleAddManager}
                  />
                  {selectedManagers?.map((el: any, ind: number) => {
                    return (
                      <div key={ind} className={styleProfile.selected}>
                        {el?.avatar_image
                          ? <img
                            src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                            className={styleProfile.image}
                          />
                          : <div className={styleProfile.selected__imgcont}><img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          /></div>}
                        <span className={styleProfile.title}>
                          {el?.user?.first_name} {el?.user?.last_name}
                        </span>
                        <span onClick={() => handleDeleteManager(ind)}>
                          <Close />
                        </span>
                      </div>
                    );
                  })}
                </div>}
                    {/* <span className={styleProfile.menagerText}>Menagers</span>
                    <div className={styleProfile.menagerList}>
                      <div className={styleProfile.menagerListImagesDiv}>
                        {managers?.map((item: any) => {
                          return (
                            <div className={styleProfile.menagerIconDiv}> <img className={styleProfile.menagerIcon} src={`${process.env.REACT_APP_API_KEY}${item?.avatar_image ? item?.avatar_image : ""}`} /></div>
                          )
                        })}
                      </div>
                      <div className={styleProfile.addMenagerDiv} onClick={() => { setActiveMenagerList(true) }}><img className={styleProfile.addMenagerIcon} src={addMenagerIcon} /></div>
                    </div> */}
                  </div>

                  <div className={styleProfile.buttonDiv}>
                    <CustomButtosWithIcon
                      title={t('deleteInfoDialogCancelButtonTitle')}
                      type={'button'}
                      // onClick={handleClose}
                      icon=''
                      titleColor="black"
                      color="white"
                    />
                    <CustomButtosWithIcon
                      title={t("save")}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                            fill="#FEFEFE"
                          />
                        </svg>
                      }
                      type="submit"
                      onClick={handleSubmit}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>);
}


export default AddCompany;
