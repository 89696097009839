import React, { ChangeEventHandler } from "react";
import style from "./Activity.module.scss";
import { CustomButtosWithIcon } from "../../../Components";

type Props = {
  data: string;
  avatar: string;
  name: string;
  date: string;
  description: string;
  isMine: boolean;
};
const CommentItem = ({
  avatar,
  name,
  date,
  data,
  description,
  isMine,
}: Props) => {
  return (
    <div
      className={`d-flex ${style.activityItem} ${
        isMine ? `${style.isMine}` : ""
      }`}
    >
      {!isMine ? (
        avatar ? (
          <img className={style.avatar} src={avatar} />
        ) : (
          <div className={style.avatarCont}>
            <img src={"/managerDefaultImg.svg"} />
          </div>
        )
      ) : null}
      <div className={`d-flex flex-column ${style.info}`}>
        <div className={`d-flex ${style.titleNameDate}`}>
          {!isMine && <div className={style.name}>{name}</div>}
        </div>
        <span className={`${style.title} ${style.comment_text}`}>{data}</span>
        {/* <div className={style.description}>{description}</div> */}
        {/* <span className={style.date}>{date}</span> */}
      </div>
      {/* <img className={style.avatar} src={avatar} /> */}
    </div>
  );
};
export default CommentItem;
