import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from "yup";
import { CustomInput, CustomButton, ChangeLanguage } from '../../Components';
import styles from '../../App.module.scss';
import LoginSvg from '../../Components/SvgCompoents/LoginSvg';
import Logo from '../../Components/SvgCompoents/Logo';
import { useSignInMutation } from '../../store/services/auth';
import { requestForToken } from '../../firebaseNotifications/firebase';

export default function Login() {
  interface Values {
    email: string;
    password: string;
  }

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [signInRequest, { error, isLoading, isSuccess }] = useSignInMutation();
  const SignupSchema = Yup.object().shape({
    email: Yup.string().trim().email(t('emailError'))
      .required(t('required')),
    password: Yup.string().trim()
      .required(t('required')),
  });
  const onSubmit = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    try {
      await signInRequest({
        ...values,
        email: values.email.trim(),
        password: values.password.trim()
      });
    } catch {
      console.log('in catch');
    }
  }
  useEffect(() => {
    if (isSuccess){ 
      requestForToken();
      navigate('/profile/dashboard', { replace: true })

    }
  }, [isSuccess])

  return (
    <div className={`d-flex justify-content-center p-[32px] align-items-center ${styles.cont}`}>
      <div className='w-100'>
        <div className={`d-flex justify-content-center`}>
          <div className={`d-flex justify-content-end ${styles.languageCont}`}><ChangeLanguage title={true} /></div>
        </div>
        <div className="d-flex d-md-none justify-content-center"><Logo /></div>
        <div className="d-flex justify-content-center align-items-center">
          <div className={styles.LoginSvg}>
            <LoginSvg />
          </div>
          <div className={styles.formCont}>
          <div className={`d-flex flex-column gap-2  ${styles.heading}`}>{t('siGnIn')}
              <div className={styles.bottomText}>
                {t('dontHaveAnAccount')}
                <span className={`ml-[4px] ${styles.signUp}`} onClick={() => navigate('/registration')}>
                  {t('signup')}
                </span>
              </div>
          </div>

            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={SignupSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched }: any) => (
                <Form>
                  <div className={styles.inputCont}>
                    <CustomInput 
                        className={styles.customInput} 
                        name='email'  
                        title={t('emailAdress')} 
                      />
                      <span className={styles.inputBottonText}>
                          Standard call, messaging or data rates may apply.
                      </span>
                  </div>
                  <CustomInput 
                    className={styles.customInput}
                    name='password' 
                    title={t('password')} 
                    type='password' 
                  />
                  <Link className={styles.forgetPassword} to='/forget-password'><span className={styles.linkTitle}>{t('forgetPassword')}</span></Link>
                  <CustomButton
                    className={styles.buttonStyle}
                    title={isLoading ? <CircularProgress color="inherit" /> : t('siGnIn')}
                    type='submit'
                    onClick={() => console.log('dbgfdjg')}
                    
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}