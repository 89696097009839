
import styles from './ChangeLanguage.module.scss';
import { useTranslation } from 'react-i18next';
import { useEditMeMutation } from '../../store/services/auth';
import { useAppSelector } from '../../store';
import { useCallback, useState } from 'react';
import arrow from '../../assets/arrow.svg'
interface CountryType {
  code: string;
  label: string;
  title: string
}
const countries: readonly CountryType[] = [
  { code: 'US', label: 'English', title: 'en' },
  { code: 'AM', label: 'Հայերեն', title: 'hy' },
  { code: 'RU', label: 'Русский', title: 'ru' }
]

interface IProps {
  title?: boolean
}

const ChangeLanguage:React.FC<IProps> = ({title}) => {
  const { t, i18n } = useTranslation();
  const [editMeRequest] = useEditMeMutation();
  const auth: any = useAppSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);

  const localStoragelocale =
    localStorage.getItem('rbc-locale') === 'en'
      ? 'us'
      : localStorage.getItem('rbc-locale');

  const handleChange = useCallback((newValue: any) => {
    const newLang = newValue === 'US' ? 'en' : newValue.toLowerCase();
    localStorage.setItem('rbc-locale', newLang);
    i18n.changeLanguage(newLang);

    if (localStorage.getItem('rbc-access-token')) {
      editMeRequest({
        language: i18n.language === 'am' ? 'hy' : i18n.language,
        id: auth?.me?.id
      });
    }

    setIsOpen(false);
  }, []);

  return (
    <div className={styles.languageCont}>
      <img
        className={styles.languageContImg}
        loading="lazy"
        width="30"
        height="24"
        src={`https://flagcdn.com/w20/${auth?.me?.language
          ? auth?.me?.language === 'hy'
            ? 'am'
            : auth?.me?.language === 'en'
            ? 'us'
            : auth?.me?.language
          : localStoragelocale}.png`}
        srcSet={`https://flagcdn.com/w40/${auth?.me?.language
          ? auth?.me?.language === 'hy'
            ? 'am'
            : auth?.me?.language === 'en'
            ? 'us'
            : auth?.me?.language
          : localStoragelocale}.png 2x`}
        alt=""
      />
      {title && <span className={styles.languageText}>
        {localStorage.getItem('rbc-locale') === 'en'
          ? 'English (United States)'
          : localStorage.getItem('rbc-locale') === 'ru'
          ? 'Русский'
          : 'Հայերեն'}
      </span>}

      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`${styles.arrow} ${isOpen ? styles.arrowUp : ''}`}
      >
        <img src={arrow} alt="arrow" />
      </div>

      {isOpen && (
        <div className={styles.dropdownMenu}>
          {countries.map((item) => (
            <div key={item.code} onClick={() => handleChange(item.code)}>
              <img
                width="20"
                src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                // srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                loading="lazy"
                alt={item.label}
              />
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChangeLanguage;