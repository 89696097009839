import React, { useEffect } from 'react';
import style from './NotificationWithRound.module.scss'
import Notification from '../SvgCompoents/Notification';
import { useUnseenNotificationsCountQuery } from '../../store/services/notifications';
import { useAppDispatch, useAppSelector } from '../../store';
import { unseenNotificationsCount } from '../../store/slice/notificationSlice';

type Props = {
    handleShowNotification: () => void,
};
const NotificationWithRound = ({ handleShowNotification }: Props) => {
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.notificationsContainer.unseenCount)
    useEffect(() => {
        dispatch(unseenNotificationsCount())
    }, []);
    useEffect(() => {
        let interval: any;
        window.onfocus = function () {
            interval = setInterval(() => {
                console.log('in Intertovallll');
                
                dispatch(unseenNotificationsCount())
            }, 30000);
        };
        window.onblur = function () {
            clearInterval(interval);
        };
        return () => clearInterval(interval);
      
    }, []);
    console.log(data,'datadatadatadata');
    
    return (<div className={style.notificationWithRound} onClick={handleShowNotification}>
        <Notification />
        {/* {data?.count &&  */}
        {data?.count !== 0 && <div className={style.round}>{data?.count}</div>}
        {/* } */}
    </div>)
}
export default NotificationWithRound;