import styleProfile from "./editManager.module.scss";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { useAddImagesMutation } from "../../../store/services/photo";
import {
  CustomButton,
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  ProfileFileUpload,
  RadioButtons,
} from "../../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserDetailsQuery } from "../../../store/services/auth";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEditManagerMutation, useGetManagerQuery, usePrefetch } from "../../../store/services/managers";
import { toast } from "react-toastify";
import { useGetTasksTypeQuery } from "../../../store/services/tasks";
import Close from "../../../Components/SvgCompoents/Close";
import EditUser from "../../../Components/SvgCompoents/EditUser";
import DeleteAccountDialog from "../../../Dialogs/DeleteAccountDialog";

interface Values {
  first_name: string;
  last_name: string;
  phone_number?: string;
  email: string;
}

export const EditManager = () => {
  const { t, i18n } = useTranslation();
  const params: any = useParams();
  const SignupSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    phone_number: Yup.string()
    .required(t("required"))
    .test(
      'unique',
      t("phoneNumberError").toString(),
      (value:any) => /^[0-9\b]+$/.test(value)
    ),
    email: Yup.string().trim().email(t("emailError")).required(t("required")),
  });
  const { data: tasksType } = useGetTasksTypeQuery({});
  const { data: managerData, isLoading } = useGetManagerQuery({id:+params?.id});
  const [tasksTypeList, setTasksTypeList] = useState<string[]>([]);
  const [selectFirstFive, setSelectFirstFive] = useState<boolean>(false);
  const [addImages, { data }] = useAddImagesMutation();
  const [disabled, setDisabled] = useState(true);
  const [submited, setSubmited] = useState<boolean>();
  const [open, setOpen] = useState(false);
  const [editManager, { isSuccess }] = useEditManagerMutation();
  const prefetchPage = usePrefetch('getManager');
  const navigate=useNavigate()
  const handleAddTasksType = useCallback(
    (tasksTypeId: any) => {      
      setTasksTypeList((prev: any) => {
        const newArray = [...prev]
        newArray.push(tasksTypeId)
        return newArray
      });
    },
    [tasksTypeList]
  );

  const handleDeleteTasksType = useCallback(
    (id: number) => {
      const copyArray = [...tasksTypeList];
      const newArray = copyArray.filter((el) => +el !== id);
      setTasksTypeList(newArray);
    },
    [tasksTypeList]
  );

  const handleSelectFirstFive = useCallback(
    () => {
      setSelectFirstFive(!selectFirstFive);
    },
    [selectFirstFive]
  );
  const handleEdit = useCallback(() =>
    setTimeout(() => {
      setDisabled(false)
    }, 500)
    , [])
  const selectedTasksType = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < tasksTypeList?.length; index++) {
      const element = tasksTypeList[index];
      for (let ind = 0; ind < tasksType?.length; ind++) {
        const el = tasksType[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [tasksTypeList, tasksType]);
  useEffect(() => {
    const list = managerData?.task_types?.map((el: any) => el?.task_type?.id)
    if (list?.includes(1)) {
      setSelectFirstFive(true)
    }
    setTasksTypeList(list)
  }, [managerData])
  // useEffect(()=>{
  //   prefetchPage()
  // },[isSuccess])
  const avatar_image = useMemo(() => {
    return managerData?.avatar_image
      ? `${process.env.REACT_APP_API_KEY}${managerData?.avatar_image}`
      : ''
  }, [managerData])
  const handleSubmite = useCallback(() => {
    setSubmited(true);
  }, [])
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false)
  }, []);
  // useEffect(() => {
  //   if (isSuccess) { toast.success(t("editManagerSuccess")); setDisabled(true) }
  // }, [isSuccess])
  return isLoading ? (
    <div></div>
  ) : (
    <div className={`w-100`}>
      <div className={styleProfile.formsCont}>
        <Formik
          initialValues={{
            first_name: managerData?.user?.first_name,
            last_name: managerData?.user?.last_name,
            email: managerData?.user?.email,
            phone_number: managerData?.phone_number,
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            if (selectedTasksType.length > 0) {
              editManager({
                ...values,
                role: 3,
                legal_type: 1,
                tin: "",
                company_name: "",
                birth_date: new Date(),
                language: i18n.language==='am'?'hy':i18n.language,
                cover_image: "",
                avatar_image: data,
                id: +params?.id,
                task_types: selectFirstFive ? [1,2,3,4,5,6,...tasksTypeList.filter((el) => +el > 6)] : tasksTypeList.filter((el) => +el > 6)
              })
                .then((res: any) => {
                  if (res.data) {
                    toast.success(t('editProfileSuccess'))
                    setDisabled(true)
                  }
                })
            }
          }}
        >
          {({ errors, touched }: any) => (
            <Form>
              <DeleteAccountDialog
              open={open}
              handleClose={handleClose}
              handleDelete={() => {
                editManager({
                  id: params?.id,
                  is_deleted: !managerData?.is_deleted
                })
                  .then((res: any) => {
                    if (res.data) {
                      // toast.success(t('editProfileSuccess'));
                      setOpen(false)
                      navigate(-1)
                    }
                  })
              }}
            />
              <div className={styleProfile.profilePhotos}>
                {/* <h1 className={styleProfile.myProfile}>{t("editManagersTitle")}</h1> */}
                <div
                  className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
                >
                  <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                    {/* <div className={styleProfile.profilePhotoEdit}>
                      {
                        data ? <img src={`${process.env.REACT_APP_API_KEY}${data}`} className={styleProfile.profilePhoto} /> :
                          managerData.avatar_image ? <img src={`${process.env.REACT_APP_API_KEY}${managerData.avatar_image}`} className={styleProfile.profilePhoto} /> :
                            <div className={styleProfile.imgContainer}><img src={"/managerDefaultImg.svg"} /></div>
                      }
                      <label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e: any) => {
                            const file: any = e?.target?.files[0];
                            if (file) {
                              const formdata = new FormData();
                              formdata.append("file_url", file);
                              addImages(formdata).then((res) => {
                                // editRequest(values)
                              });
                            }
                          }}
                        />
                        <div className={styleProfile.changePhotoCont}>
                          <img src={"/camera.svg"} />
                        </div>
                      </label>
                    </div> */}
                    <ProfileFileUpload
                      avatar_image={managerData?.avatar_image
                        ? `${process.env.REACT_APP_API_KEY}${managerData?.avatar_image}`
                        : ''}
                      request={(img: string) => {
                        editManager({
                          avatar_image: img,
                          id: +params?.id,
                        });
                      }} />
                       <div className={`d-flex flex-wrap justify-content-center gap-2 justify-md-content-between ${styleProfile.buttonsCont}`}>
                    <CustomButtosWithIcon
                      color='red'
                      title={t('delete')}
                      type={'button'}
                      onClick={handleOpen}
                    icon={<DeleteIcon />}
                    />
                    {disabled
                      ? <CustomButtosWithIcon
                        title={t('editProfile')}
                        type={'button'}
                        onClick={handleEdit}
                        icon={<EditUser />}
                      />
                      : <CustomButtosWithIcon
                        title={t("save")}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                              fill="#FEFEFE"
                            />
                          </svg>
                        }
                        type="submit"
                        onClick={handleSubmite}
                      />
                    }
                  </div>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}
              >
                <CustomInput
                  name="first_name"
                  title={t("firstName")}
                  readonly={disabled}
                />
                <CustomInput
                  name="last_name"
                  title={t("lastName")}
                  readonly={disabled}
                />
                <CustomInput
                  name="phone_number"
                  title={t("phoneNumber")}
                  readonly={disabled}
                />
                <CustomInput
                  name="email"
                  title={t("emailAdress")}
                  readonly={true}
                />
                <div className={`${styleProfile.withSelected} position-relative`}>
                  <RadioButtons
                    readonly={disabled}
                    title={t("taskTypes")}
                    label={t("workWithEmployee")}
                    selectFirstFive={selectFirstFive}
                    array={tasksType}
                    selectedList={tasksTypeList}
                    onClick={(id: number) => {
                      tasksTypeList?.filter((type) => +type === id)[0]
                        ? handleDeleteTasksType(id)
                        : handleAddTasksType(id)
                    }}
                    handleSelectFirstFive={handleSelectFirstFive}
                  />
                  {submited && selectedTasksType.length === 0 && <div className='error'>{t('required')}</div>}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
