import FormControl from '@mui/material/FormControl';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import styles from './CustomSelect.module.scss';

interface IProps {
  label: string,
  list: any,
  onChange: (val: string) => void,
  value?: any,
  multiple?: boolean,
  showValue?: boolean,
  readonly?: boolean
}

const CustomSelect = ({ readonly = false, ...props }: IProps) => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .Mui-selected': {
     backgroundColor:"#F6FAFD",
    },
    '& .MuiInputBase-input': {
      // height: 32,
      backgroundColor:"#F6FAFD",
      borderRadius: 8,
      position: 'relative',
      border: "1px solid #EAEEF4",
      fontSize: 16,
      padding: '9px 26px 9px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 12,
        borderColor: '#006884',
        boxShadow: 'none',
        backgroundColor:"#F6FAFD",
      },
    },
  }));

  const handleChange = (val: SelectChangeEvent) => {
    props.onChange(val.target.value as any)
  }

  return (
    <label className={styles.cont}>
      <div className={styles.label}>{props.label}</div>
      <FormControl sx={{ maxWidth: 501, width: '100%', height: 45 }} variant="standard">
        <Select
          disabled={readonly}
          multiple={props.multiple}
          value={props.value ? props.value : []}
          input={<BootstrapInput />}
          onChange={handleChange}
          renderValue={(p: any) => props?.showValue ? props?.list?.filter((el: any) => el.id === p)[0]?.name : ''}
          sx={{ maxWidth: 501, width: '100%', marginBottom: 24, height: 45 }}
        >
          {props.list?.map((el: { id: number, name: string }) => {
            return <MenuItem key={el.id} value={+el.id}>{el?.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    </label>
  );
};
export default CustomSelect;