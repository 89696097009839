import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import {
  CustomButton,
  CustomDayPicker,
  DashbordSelect,
} from "../../../Components";
import { useAppDispatch, useAppSelector } from "../../../store";
import { CreateTask } from "../../../Dialogs";
import styles from "./Dashboard.module.scss";
import DashboardCard from "../../../Components/DashboardCard";
import { useGetTasksQuery, useGetTasksTypeQuery } from "../../../store/services/tasks";
import moment from "moment";
import { Pagination, Stack } from '@mui/material';
import { usePrefetch } from '../../../store/services/tasks';
import { useGetCompaniesQuery } from "../../../store/services/companies";
import { useGetManagersQuery } from "../../../store/services/managers";
import { ICompanyItem } from "../../../types";
import { toast } from "react-toastify";
import { onMessageListener } from "../../../firebaseNotifications/firebase";
import { Toaster } from "react-hot-toast";
import filterIcon from "../../../assets/filter-add.svg"

export default function Dashboard() {
  const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
  const [open, setOpen] = useState<boolean>(false);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({});
  const [activePage, setActivePage] = useState<number>(1);
  const auth: any = useAppSelector((state) => state.auth);
  const taskData = useAppSelector((state: any) => state.createTask.taskData);
  const myTaskTypes = useAppSelector((state: any) => state?.auth?.me?.task_types?.map((el: any) => el?.task_type))
  const { data: companies } = useGetCompaniesQuery({ limit: 100000 });
  const { data: managers } = useGetManagersQuery({ limit: 100000 });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  console.log(task_types,"00000");
  const { data: tasksCreated, isLoading:isLoadingCreated } = useGetTasksQuery({
    limit: 100000000000000000,
    status: "created",
  });
 console.log(tasksCreated,"created");

 const { data: tasksDone, isLoading:isLoadingDone } = useGetTasksQuery({
  limit: 100000000000000000,
  status: "done",
});
console.log(tasksDone,"done");

const { data: tasksAnsweredByAccountant, isLoading:isLoadingAnsweredByAccountant } = useGetTasksQuery({
  limit: 100000000000000000,
  status: "answeredByAccountant",
});
console.log(tasksAnsweredByAccountant,"answeredByAccountant");

const { data: tasksDispute, isLoading:isLoadingDispute } = useGetTasksQuery({
  limit: 100000000000000000,
  status: "dispute",
});
console.log(tasksDispute,"dispute");

const { data: tasksDeleted, isLoading:isLoadingDeleted } = useGetTasksQuery({
  limit: 100000000000000000,
  status: "deleted",
});
console.log(tasksDeleted,"deleted");

 
  const { data, isLoading } = useGetTasksQuery(filter?.from_date ? {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter,
    from_date: filter?.from_date ? filter?.from_date?.toISOString() : '',
    to_date: filter?.to_date ? filter?.to_date?.toISOString() : ''
  } : {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter
  });

  const userRole = localStorage.getItem("rbcRole");
  // @ts-ignore
  const rbcId: number = +localStorage.getItem('rbcId');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const prefetchPage = usePrefetch('getTasks');
  const handleClickOpen = () => {
    if (auth?.me?.accountant) {
      setOpen(true);
    } else {
      toast.info(t("youCantCreateTask"))
    }

  };
  // const handleClose = () => {
  //   localStorage.setItem('cmpId', taskData.company_id);
  //   localStorage.setItem('taskTypeId', taskData.task_type_id);
  //   const tarif = companies?.filter((el: ICompanyItem) => el.id === taskData?.company_id)[0]?.tarif
  //   if (tarif === 1 && taskData.task_type_id === 11) {
  //     toast.info(t("youCantCreateTaskForThisCompany"))
  //   } else {
  //     switch (taskData.task_type_id.toString()) {
  //       case "1":
  //         return navigate("/profile/companies/create-task");
  //       case "2":
  //         return navigate("/profile/companies/create-vacation");
  //       case "3":
  //         navigate("/profile/companies/create-provision-allowance");
  //         break;
  //       case "4":
  //         navigate("/profile/companies/create-dismissal");
  //         break;
  //       case "5":
  //         navigate("/profile/companies/change-salary-position");
  //         break;
  //       case "6":
  //         navigate("/profile/companies/create-bonus");
  //         break;
  //       case "7":
  //         navigate("/profile/companies/landing-bill");
  //         break;
  //       case "8":
  //         navigate("/profile/companies/estimate-task");
  //         break;
  //       case "9":
  //         navigate("/profile/companies/create-invoice");
  //         break;
  //       case "10":
  //         navigate("/profile/companies/free");
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  //   setOpen(false);
  // };
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [showCreateVacation, setShowCreateVacation] = useState(false);
  const [createProvisionAllowance, setCreateProvisionAllowance] = useState(false);
  const [createDismissal, setCreateDismissal] = useState(false);
  const [changeSalaryPosition, setChangeSalaryPosition] = useState(false);
  const [createBonus, setCreateBonus] = useState(false);
  const [landingBill, setLandingBill] = useState(false);
  const [estimateTask, setEstimateTask] = useState(false);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [free, setFree] = useState(false);
  const handleClose = () => {
    localStorage.setItem('cmpId', taskData.company_id);
    localStorage.setItem('taskTypeId', taskData.task_type_id);
    const tarif = companies?.filter((el: ICompanyItem) => el.id === taskData?.company_id)[0]?.tarif
    if (tarif === 1 && taskData.task_type_id === 11) {
      toast.info(t("youCantCreateTaskForThisCompany"))
    } else {
      switch (taskData.task_type_id.toString()) {
        case "1":
          setShowCreateTask(true);
          break;
        case "2":
          setShowCreateVacation(true);
          break;
        case "3":
          // navigate("/profile/companies/create-provision-allowance");
          setCreateProvisionAllowance(true)
          break;
        case "4":
          setCreateDismissal(true)
          break;
        case "5":
          setChangeSalaryPosition(true)
          break;
        case "6":
          setCreateBonus(true)
          break;
        case "7":
          // navigate("/profile/companies/landing-bill");
          setLandingBill(true)
          break;
        case "8":
          setEstimateTask(true)
          // navigate("/profile/companies/estimate-task");
          break;
        case "9":
          setCreateInvoice(true)
          // navigate("/profile/companies/create-invoice");
          break;
        case "10":
          setFree(true)
          // navigate("/profile/companies/free");
          break;
        default:
          break;
      }
    }

    setOpen(false);
  };

  const handleSelectDateRange = useCallback((value: any) => {
    setFilter({ ...filter, from_date: value?.from, to_date: value?.to })
  }, [filter]);

  const handleChangeFilter = useCallback((value: any) => {
    const keys = Object.keys(value)
    let newValue = {};
    if (value[keys[0]]) {
      newValue = { ...filter, ...value }
    } else {
      const filterClone = { ...filter };
      delete filterClone[keys[0]]
      newValue = { ...filterClone }
    }
    setFilter(newValue);

    const queryStr = queryString.stringify({ ...newValue });
    navigate((window.location.pathname + "?" + queryStr) as any);
  }, [filter]);

  useEffect(() => {
    if (notification?.data?.push_type !== 'TASK_COMMENT') {
      prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
    }
  }, [notification])

  useEffect(() => {
    const myParams: any = {}
    const queryParams: any = new URLSearchParams(window.location.search)
    for (const [key, value] of queryParams) {
      myParams[key] = value
    }
    setFilter({ ...myParams })
  }, [window.location.search])


  return (
    <div className={`w-100 dashboard`}>
      <Toaster />
      <div className="d-flex justify-content-between">
        <CreateTask
          open={open}
          handleClose={handleClose}
          handleOpen={handleClickOpen}
          showCreateTask={showCreateTask}
          setShowCreateTask={setShowCreateTask}
          showCreateVacation={showCreateVacation}
          setShowCreateVacation={setShowCreateVacation}
          createProvisionAllowance={createProvisionAllowance}
          setCreateProvisionAllowance={setCreateProvisionAllowance}
          createDismissal={createDismissal}
          setCreateDismissal={setCreateDismissal}
          changeSalaryPosition={changeSalaryPosition}
          setChangeSalaryPosition={setChangeSalaryPosition}
          createBonus={createBonus}
          setCreateBonus={setCreateBonus}
          landingBill={landingBill}
          setLandingBill={setLandingBill}
          estimateTask={estimateTask}
          setEstimateTask={setEstimateTask}
          createInvoice={createInvoice}
          setCreateInvoice={setCreateInvoice}
          free={free}
          setFree={setFree}
        />

      </div>
      <div className={styles.filterMain}>
        <div className={styles.filterItem}>
          <div className={styles.filterDiv} onClick={() => setFilterActive(!filterActive)}>
            <div className={styles.filterIconDiv}><img className={styles.filterIconStyle} src={filterIcon} /></div>
            <span className={styles.filterText}>{t("filter")}</span>
          </div>
          {userRole !== "2" && (
            <div className={styles.createTask}>
              <CustomButton onClick={handleClickOpen} title={`+ ${t("createTasks")}`} type="button" />
            </div>
          )}
        </div>
        {filterActive &&
          <div className={`d-flex gap-4 w-100 ${styles.filterRightSideCont}`}>
            {/* <SearchInput onClick={() => console.log("dddddd")} /> */}
            <div className={styles.selectDiv}>
              <DashbordSelect
                showValue={true}
                label={t('status')}
                value={filter.status ? filter.status : 0}
                list={[{ name: `${t('created')}`, id: 'created' },
                { name: `${t('answeredByAccountant')}`, id: 'answeredByAccountant' },
                { name: `${t('dispute')}`, id: 'dispute' },
                { name: `${t('done')}`, id: 'done' },
                { name: `${t('deleted')}`, id: 'deleted' },
                ]}
                onChange={(e) => handleChangeFilter({ status: e })}
              />
            </div>
            <div className={styles.selectDiv}>
              <DashbordSelect
                showValue={true}
                label={t('companies')}
                value={filter.company_id ? +filter.company_id : 0}
                list={companies?.filter((item: any) => item?.is_active)?.map((el: any) => ({ name: el?.name, id: el?.id }))}
                onChange={(e) => handleChangeFilter({ company_id: e })}

              />
            </div>
            <div className={styles.selectDiv}>
              <DashbordSelect
                showValue={true}
                label={t('taskTypes')}
                value={filter.type_id ? +filter.type_id : 0}
                list={task_types?.map((el: any) => ({ name: t(`${el?.name}`), id: el?.id }))}
                onChange={(e) => handleChangeFilter({ type_id: e })}
              />
            </div>
            <div className={styles.selectDiv}>
              <DashbordSelect
                showValue={true}
                label={t('managers')}
                value={filter.manager_id ? +filter.manager_id : 0}
                list={managers?.map((el: any) => ({ name: `${el?.user?.first_name} ${el?.user?.last_name}`, id: el?.id }))}
                onChange={(e) => handleChangeFilter({ manager_id: e })}
              />
            </div>
            <div className={styles.selectDiv}>
              <CustomDayPicker
                className={styles.dayPickerStyle}
                titleStyle={true}
                mode="range"
                title={t('date')}
                selected={{ from: filter.from_date, to: filter.to_date }}
                submited={false}
                setSelected={handleSelectDateRange}
              />
            </div>
          </div>}

      </div>
      {/* <div className={styles.filterIcon}>
          <Filter />
        </div> */}
      <div className={`${filterActive ? styles.DashboardCardCont100 : styles.DashboardCardCont}`}>
        <div className={styles.dashboardItem}>
          <div className={styles.dashboardItemHeader}>
            <span className={styles.dashboardItemHeaderTitle}>{t('created')}</span>
            <span className={styles.dashboardItemHeaderTask}>{`${tasksCreated?.results?.length} task `}</span>
          </div>
          <div className={styles.dashboardItemTask}>
            {tasksCreated?.results?.map((el: any, ind: number) => (
              <DashboardCard
                key={ind}
                task_name={el.task_name}
                task_type={t(`${el.task_type.name}`)}
                id={el.id}
                status={el.status}
                company_name={el?.company?.name}
                created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
                manager={el.creator.id !== rbcId
                  ? `${el.creator.user.first_name ? el.creator.user.first_name : ''} ${el.creator.user.last_name ? el.creator.user.last_name : ''}`
                  : ""}
              />
            ))}
          </div>
        </div>
        <div className={styles.dashboardItem}>
          <div className={styles.dashboardItemHeader}>
            <span className={styles.dashboardItemHeaderTitle}>{t('done')}</span>
            <span className={styles.dashboardItemHeaderTask}>{`${tasksDone?.results?.length} task `}</span>
          </div>
          <div className={styles.dashboardItemTask}>
            {tasksDone?.results?.map((el: any, ind: number) => (
              <DashboardCard
                key={ind}
                task_name={el.task_name}
                task_type={t(`${el.task_type.name}`)}
                id={el.id}
                status={el.status}
                company_name={el?.company?.name}
                created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
                manager={el.creator.id !== rbcId
                  ? `${el.creator.user.first_name ? el.creator.user.first_name : ''} ${el.creator.user.last_name ? el.creator.user.last_name : ''}`
                  : ""}
              />
            ))}
          </div>
        </div>
        <div className={styles.dashboardItem}>
          <div className={styles.dashboardItemHeader}>
            <span className={styles.dashboardItemHeaderTitle}>{t('dispute')}</span>
            <span className={styles.dashboardItemHeaderTask}>{`${tasksDispute?.results?.length} task `}</span>
          </div>
          <div className={styles.dashboardItemTask}>
            {tasksDispute?.results?.map((el: any, ind: number) => (
              <DashboardCard
                key={ind}
                task_name={el.task_name}
                task_type={t(`${el.task_type.name}`)}
                id={el.id}
                status={el.status}
                company_name={el?.company?.name}
                created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
                manager={el.creator.id !== rbcId
                  ? `${el.creator.user.first_name ? el.creator.user.first_name : ''} ${el.creator.user.last_name ? el.creator.user.last_name : ''}`
                  : ""}
              />
            ))}
          </div>
        </div>
        <div className={styles.dashboardItem}>
          <div className={styles.dashboardItemHeader}>
            <span className={styles.dashboardItemHeaderTitle}>{t('answeredByAccountant')}</span>
            <span className={styles.dashboardItemHeaderTask}>{`${tasksAnsweredByAccountant?.results?.length} task `}</span>
          </div>
          <div className={styles.dashboardItemTask}>
            {tasksAnsweredByAccountant?.results?.map((el: any, ind: number) => (

              <DashboardCard
                key={ind}
                task_name={el.task_name}
                task_type={t(`${el.task_type.name}`)}
                id={el.id}
                status={el.status}
                company_name={el?.company?.name}
                created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
                manager={el.creator.id !== rbcId
                  ? `${el.creator.user.first_name ? el.creator.user.first_name : ''} ${el.creator.user.last_name ? el.creator.user.last_name : ''}`
                  : ""}
              />
            ))}
          </div>
        </div>
        <div className={styles.dashboardItem}>
          <div className={styles.dashboardItemHeader}>
            <span className={styles.dashboardItemHeaderTitle}>{t('deleted')}</span>
            <span className={styles.dashboardItemHeaderTask}>{`${tasksDeleted?.results?.length} task `}</span>
          </div>
          <div className={styles.dashboardItemTask}>
            {tasksDeleted?.results?.map((el: any, ind: number) => (
              <DashboardCard
                key={ind}
                task_name={el.task_name}
                task_type={t(`${el.task_type.name}`)}
                id={el.id}
                status={el.status}
                company_name={el?.company?.name}
                created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
                manager={el.creator.id !== rbcId
                  ? `${el.creator.user.first_name ? el.creator.user.first_name : ''} ${el.creator.user.last_name ? el.creator.user.last_name : ''}`
                  : ""}
              />
            ))}
          </div>
        </div>
        {/* {data?.results?.map((el: any, ind: number) => (
          <DashboardCard
            key={ind}
            task_name={el.task_name}
            task_type={t(`${el.task_type.name}`)}
            id={el.id}
            status={el.status}
            company_name={el?.company?.name}
            created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
            manager={el.creator.id !== rbcId
              ? `${el.creator.user.first_name ? el.creator.user.first_name : ''} ${el.creator.user.last_name ? el.creator.user.last_name : ''}`
              : ""}
          />
        ))} */}
      </div>
      {/* {data?.count > 12 && (
        <Stack spacing={2} marginTop={10}>
          <Pagination
            count={Math.ceil(data?.count / 12)}
            page={activePage}
            showFirstButton
            showLastButton
            onChange={(_, page) => {
              setActivePage(page);

            }}
          />
        </Stack>
      )} */}
      {/* {!isLoading && data?.results?.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>} */}

    </div>
  );
}
