import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, DetailItem, FileDownload } from '../../../Components';
import { useEditHolidayTasksByIdMutation } from '../../../store/services/tasks';
import { Formik, FormikHelpers, Form } from 'formik';
import { toast } from 'react-toastify';
import Recording from '../../../Components/Recording';
import { useMemo } from 'react';
import { ITaskPage } from '../../../types';
import { getFileName } from '../../../Utils';
interface DataTask {
  data: ITaskPage
}
export default function Holiday({ data }: DataTask) {
  const { t } = useTranslation();
  const description = t('salaryDescription');
  const navigate = useNavigate();
  const [editHolidayTasksById] = useEditHolidayTasksByIdMutation();

  const application = useMemo(() => {
    return getFileName(data?.employee_holiday?.application)
  }, [data?.employee_holiday?.application]);
  const SignupSchema = Yup.object().shape({
    salary: Yup.number()
      .required(t('required'))
      .min(0, t('required')),
  });
  interface Values {
    salary?: number
  }
  return (
    <div className={`w-100`}>
      <div className={styles.taskType}>
        <div className='d-flex justify-content-between flex-nowrap'>
          <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
          <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          <div>
            <div className={styles.heading}>{t('detail')} </div>
            <div className={styles.detailCont}>
              {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
              {data?.employee_holiday?.recording && <div className={styles.application}>
                <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
                <Recording src={data?.employee_holiday?.recording} />
              </div>}
            </div>
          </div>
          {data?.employee_holiday?.application && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('accost')}</h4>
            <div className={`d-flex ${styles.cardCont}`}>
              <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_holiday.application}`}
                status={data?.status}
                title={application}
              // deleteItem={() => { console.log('dgf') }}
              />
            </div>
          </div>}
        </div>
      </div>
      {data.status === "created" && <>
        <div className={styles.taskType}>
          <Formik
            initialValues={{
            }}
            validationSchema={SignupSchema}
            onSubmit={(
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              editHolidayTasksById({ ...values, id: data?.employee_holiday?.id, status: 'done' })
                .then((res: any) => {
                  if (res?.data?.id) {
                    toast.success('Գործընթացը հաջողությամբ կատարվել է');
                    navigate('/profile/dashboard')
                  }
                })
            }}
          >
            {({ errors, touched, values }: any) => (
              <Form >
                <div className='d-flex justify-content-between'>
                  <div className={styles.heading}> {t('accountantSalary')} </div>
                  {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                    title={t("answer")}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                          fill="#FEFEFE"
                        />
                      </svg>
                    }
                    type="submit"
                  />}
                </div>
                <CustomInput name='salary' title={t('salary')} type='number' className='taskInput' />
              </Form>)}
          </Formik>
        </div>
      </>}
      {data.status === "done" && <>
        <div className={styles.taskType}>
          <div className={styles.heading}> {t('accountantSalary')} </div>
          {data?.employee_holiday && <div>{description.replace('<<X<<', new Intl.NumberFormat(
            'en-US',
          ).format(data?.employee_holiday?.salary))}</div>}
        </div>
      </>}
    </div>
  );
}


