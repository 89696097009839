import { useTranslation } from "react-i18next";
import styles from "./DetailItem.module.scss";
export default function DetailItem({
  name,
  value,
}: {
  name: string;
  value: string | undefined;
}) {
  const { t } = useTranslation();
  return (
    <div className={` ${styles.detailItem}`}>
      <p className={styles.detailName}>{name}</p>
      <p className={styles.detailValue}>{value}</p>
    </div>
  );
}
