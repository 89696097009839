import React from 'react';
import style from './NotificationItem.module.scss'

type Props = {
  title: string,
  avatar: string,
  name: string,
  date: string,
  is_reed: boolean,
  onClick: () => void
};
const NotificationItem = ({ avatar, is_reed, name, date, title, onClick }: Props) => {  
  return (<div className={style.notificationItem} onClick={onClick}>
    <div className={is_reed ? `${style.color} ${style.colorTransparent}` : `${style.color}`} />
    {avatar
      ? <img className={style.avatar} src={avatar} />
      : <div className={style.avatarCont}><img src={'/managerDefaultImg.svg'} /></div>}
    <div className={style.titleNameDate}>
    <span className={style.name}>{name}</span>
      <span className={style.title}>{title}</span>
      <span className={style.date}>{date}</span>
    </div>
  </div>)
}
export default NotificationItem;