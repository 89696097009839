import styles from './CustomButton.module.scss';
interface IProps {
  title?: any,
  disabled?: boolean,
  type: any,
  className?: string,
  onClick?: () => void
}
const CustomButton = (props: IProps) => {
  return <button
    className={`${styles.button} ${props.className} d-flex justify-content-center align-items-center `}
    disabled={props.disabled}
    type={props.type}
    onClick={props.onClick}>
    {props?.title}
  </button>
}
export default CustomButton;