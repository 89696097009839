const RecordingIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4082_5616)">
<path d="M10.0005 17.4415C5.80049 17.4415 2.37549 14.0248 2.37549 9.8165V8.24984C2.37549 7.92484 2.64215 7.6665 2.95882 7.6665C3.27549 7.6665 3.54216 7.93317 3.54216 8.24984V9.8165C3.54216 13.3748 6.43382 16.2665 9.99215 16.2665C13.5505 16.2665 16.4422 13.3748 16.4422 9.8165V8.24984C16.4422 7.92484 16.7088 7.6665 17.0255 7.6665C17.3422 7.6665 17.6088 7.93317 17.6088 8.24984V9.8165C17.6255 14.0248 14.2005 17.4415 10.0005 17.4415Z" fill="#62708D"/>
<path d="M10.0003 0.833252C7.20033 0.833252 4.91699 3.11659 4.91699 5.91658V9.82492C4.91699 12.6249 7.20033 14.9082 10.0003 14.9082C12.8003 14.9082 15.0837 12.6249 15.0837 9.82492V5.91658C15.0837 3.11659 12.8003 0.833252 10.0003 0.833252ZM11.817 7.99158C11.7587 8.21658 11.5503 8.36658 11.3253 8.36658C11.2837 8.36658 11.2337 8.35825 11.192 8.34992C10.342 8.11658 9.44199 8.11658 8.59199 8.34992C8.31699 8.42492 8.04199 8.26658 7.96699 7.99158C7.89199 7.72492 8.05033 7.44158 8.32533 7.36658C9.35033 7.08325 10.4337 7.08325 11.4587 7.36658C11.7337 7.44158 11.892 7.71658 11.817 7.99158ZM12.5253 5.68325C12.4503 5.89158 12.2587 6.01658 12.0503 6.01658C11.992 6.01658 11.9337 6.00825 11.8753 5.98325C10.6003 5.51658 9.20033 5.51658 7.92533 5.98325C7.65866 6.08325 7.36699 5.94992 7.26699 5.68325C7.17533 5.42492 7.30866 5.13325 7.57533 5.03325C9.07533 4.49159 10.7253 4.49159 12.217 5.03325C12.4837 5.13325 12.617 5.42492 12.5253 5.68325Z" fill="#62708D"/>
<path d="M9.16667 19.1667C9.16667 19.627 9.53976 20.0001 10 20.0001C10.4602 20.0001 10.8333 19.627 10.8333 19.1667L9.16667 19.1667ZM9.16667 16.6667L9.16667 19.1667L10.8333 19.1667L10.8333 16.6667L9.16667 16.6667Z" fill="#62708D"/>
</g>
<defs>
<clipPath id="clip0_4082_5616">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
{/* <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
<path d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z" fill="#006884"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.0656 6.20898C15.3597 6.21102 14.6832 6.49233 14.1841 6.99147C13.6849 7.49061 13.4035 8.16701 13.4014 8.87294V17.7021C13.4035 18.4081 13.6848 19.0846 14.184 19.5838C14.6832 20.0831 15.3596 20.3645 16.0656 20.3667H17.9345C18.6406 20.3646 19.3171 20.0832 19.8163 19.5839C20.3155 19.0847 20.5969 18.4081 20.599 17.7021V8.873C20.5969 8.16703 20.3155 7.49057 19.8163 6.9914C19.317 6.49224 18.6405 6.21095 17.9345 6.20898H16.0656ZM16.1022 24.6518C14.0536 24.4246 12.1603 23.4513 10.7832 21.9176C9.40621 20.3839 8.64179 18.3971 8.63574 16.3359C8.63778 16.0991 8.73328 15.8727 8.90145 15.706C9.06962 15.5393 9.29684 15.4457 9.53365 15.4457C9.77047 15.4457 9.99769 15.5393 10.1659 15.706C10.334 15.8727 10.4295 16.0991 10.4316 16.3359C10.4371 18.0744 11.1316 19.7398 12.3628 20.9671C13.5941 22.1944 15.2616 22.8836 17.0001 22.8836C18.7386 22.8836 20.4062 22.1944 21.6374 20.9671C22.8686 19.7398 23.5631 18.0744 23.5686 16.3359C23.5686 16.0978 23.6633 15.8693 23.8317 15.7009C24.0001 15.5325 24.2285 15.4379 24.4667 15.4379C24.7049 15.4379 24.9333 15.5325 25.1018 15.7009C25.2702 15.8693 25.3648 16.0978 25.3648 16.3359C25.3588 18.3972 24.5943 20.3841 23.2172 21.9178C21.8401 23.4515 19.9466 24.4247 17.898 24.6519V27.791H16.1022V24.6519V24.6518Z" fill="white"/>
</svg> */}
export default RecordingIcon