import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./CreateTask.module.scss";
import {
  CustomButtosWithIcon,
  CustomDayPicker,
  CustomInput,
} from "../../../Components";
import { useAddTasksSalaryMutation } from "../../../store/services/tasks";
import { useAppSelector } from "../../../store";
import Recording from "../../../Components/Recording";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/cl.svg";

interface IProps {
  setChangeSalaryPosition?: any;
}

const ChangeProvisionAllowance: React.FC<IProps> = (props) => {
  const { setChangeSalaryPosition } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [recording, setRecording] = useState<string>();
  const [date, setDate] = useState<string>();
  const [submited, setSubmit] = useState<boolean>();
  const [addTasksSalaryRequest] = useAddTasksSalaryMutation();
  const taskData = useAppSelector((state) => state.createTask.taskData);

  interface Values {
    task_name: string;
    employee_name: string;
    salary?: number;
    position: string;
  }
  // [Yup.ref('salary')],
  const SignupSchema = Yup.object().shape({
    task_name: Yup.string().required(t("required")),
    employee_name: Yup.string().required(t("required")),
    salary: Yup.number().test("position", t("required"), function (value) {
      const options: any = this.options;
      const position = options?.parent?.position;
      if (!position && !value) {
        return false;
      }
      return true;
    }),
    position: Yup.string().test("salary", t("required"), function (value) {
      const options: any = this.options;
      const salary = options?.parent?.salary;
      if (!salary && !value) {
        return false;
      }
      return true;
    }),
  });
  const handleSelectDate = useCallback((value: string) => {
    setDate(value);
  }, []);
  const handleSubmit = useCallback(() => {
    setSubmit(true);
  }, []);
  const handleRecording = useCallback((url: string) => {
    setRecording(url);
  }, []);
  return (
    <div className={styles.cont}>
      <div className={styles.header}>
        <span className={styles.headerTitle}>
          {t("EmployeeSalaryPosition")}
        </span>
        <div
          className={styles.closeButton}
          onClick={() => {
            setChangeSalaryPosition(false);
          }}
        >
          <img src={closeIcon} />
        </div>
      </div>
      <div className={styles.main}>
        <Formik
          initialValues={{
            task_name: "",
            employee_name: "",
            position: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            if (date) {
              addTasksSalaryRequest({
                ...values,
                change_date: date,
                status: "created",
                company: taskData?.company_id
                  ? taskData?.company_id
                  : localStorage.getItem("cmpId"),
                recording,
              }).then((res: any) => {
                if (res?.data?.id) {
                  toast.success("Գործընթացը հաջողությամբ կատարվել է");
                  navigate("/profile/dashboard");
                }
              });
            }
          }}
        >
          {({ errors, touched }: any) => (
            <Form className="w-100">
              {/* <div className='d-flex justify-content-between'>
              <h1 className={styles.pageHeading}>{t('EmployeeSalaryPosition')}</h1>

            </div> */}
              <div className={styles.taskNameDiv}>
                <CustomInput
                  name="task_name"
                  title={t("taskName")}
                  classInput={styles.inputeStyle}
                />
              </div>
              <div className={styles.picerDiv}>
                <CustomInput
                  name="employee_name"
                  title={t("firstName")}
                  classInput={styles.inputeStyle}
                />
                <CustomInput
                  name="salary"
                  type="number"
                  title={t("salary")}
                  classInput={styles.inputeStyle}
                />
              </div>
              <div className={styles.picerDiv}>
                <CustomInput
                  name="position"
                  title={t("role")}
                  classInput={styles.inputeStyle}
                />
                <CustomDayPicker
                  mode="single"
                  setSelected={handleSelectDate}
                  name="date_contract"
                  title={t("dataContract")}
                  className="taskInput"
                  selected={date}
                  submited={submited}
                />
              </div>

              <div>
                <div
                  className={`${styles.sectionHeading} ${styles.cardContHeading}`}
                >
                  {t("voiceMessage")}
                </div>
                <Recording handleRecording={handleRecording} />
              </div>
              <div className={styles.buttonDiv}>
                <CustomButtosWithIcon
                  title={t("deleteInfoDialogCancelButtonTitle")}
                  type={"button"}
                  onClick={() => {
                    setChangeSalaryPosition(false);
                  }}
                  icon=""
                  titleColor="black"
                  color="white"
                />
                <CustomButtosWithIcon
                  title={t("create")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeProvisionAllowance;
