import style from './SearchInput.module.scss'

type Props = {
  onClick: () => void
};
const SearchInput = ({ onClick }: Props) => {
  return (<div className={`d-flex ${style.searchCont}`}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0259 12.8474L17.5948 16.4163L16.4163 17.5948L12.8474 14.0259C11.5642 15.0526 9.93675 15.6667 8.16675 15.6667C4.02675 15.6667 0.666748 12.3067 0.666748 8.16675C0.666748 4.02675 4.02675 0.666748 8.16675 0.666748C12.3067 0.666748 15.6667 4.02675 15.6667 8.16675C15.6667 9.93675 15.0526 11.5642 14.0259 12.8474ZM12.354 12.2291C13.373 11.1789 14.0001 9.74642 14.0001 8.16675C14.0001 4.94383 11.3897 2.33341 8.16675 2.33341C4.94383 2.33341 2.33341 4.94383 2.33341 8.16675C2.33341 11.3897 4.94383 14.0001 8.16675 14.0001C9.74642 14.0001 11.1789 13.373 12.2291 12.354L12.354 12.2291Z" fill="#62708D"/>
</svg>

    {/* <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <g clip-path="url(#clip0_658_3280)">
        <path d="M23.25 21H22.065L21.645 20.595C23.445 18.495 24.375 15.63 23.865 12.585C23.16 8.41496 19.68 5.08496 15.48 4.57496C9.13496 3.79496 3.79496 9.13496 4.57496 15.48C5.08496 19.68 8.41496 23.16 12.585 23.865C15.63 24.375 18.495 23.445 20.595 21.645L21 22.065V23.25L27.375 29.625C27.99 30.24 28.995 30.24 29.61 29.625C30.225 29.01 30.225 28.005 29.61 27.39L23.25 21ZM14.25 21C10.515 21 7.49996 17.985 7.49996 14.25C7.49996 10.515 10.515 7.49996 14.25 7.49996C17.985 7.49996 21 10.515 21 14.25C21 17.985 17.985 21 14.25 21Z" fill="#1C2E45" fill-opacity="0.6" />
      </g>
      <defs>
        <clipPath id="clip0_658_3280">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg> */}
    <input className={style.searchInput}
    placeholder='Search' />
  </div>)
}
export default SearchInput;