import { useCallback, useMemo, useRef, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { BankScore, CustomButtosWithIcon, CustomDayPicker, CustomInput, CustomRadio, CustomSelectFormik, CustomizedTables } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import styles from './CreateTask.module.scss';
import { useAddEstimateMutation, useAddLadingBillMutation, useAddTasksBonusMutation, useGetbankQuery } from '../../../store/services/tasks';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import { useAddImagesMutation } from '../../../store/services/photo';
import { useAppSelector } from '../../../store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IBankItem, IFiles } from '../../../types';
import AddIcon from '../../../Components/SvgCompoents/AddIcon';
import { ProductDialog } from '../../../Dialogs';
import { useGetCompanyByIdQuery } from '../../../store/services/companies';
import Recording from '../../../Components/Recording';
import closeIcon from "../../../assets/cl.svg"
import docIcon from "../../../assets/paperclip-2.svg"
import addProductIcon from "../../../assets/add-square.svg"

interface IProps {
    setEstimateTask?: any
}

interface IProduct {
    name: string
    price: number
    count: number
    vat: number
    sum: number
}
const EstimateTask: React.FC<IProps> = (props) => {
    const { setEstimateTask } = props
    const cmpId = localStorage.getItem('cmpId');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [submited, setSubmited] = useState(false);
    const [files, setFiles] = useState<IFiles>({});
    const [showTable, setShowTable] = useState<boolean>(false);
    const [shippingDate, setShippingDate] = useState<string>();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [recording, setRecording] = useState<string>();
    const taskData = useAppSelector((state) => state.createTask.taskData);
    const { data: banks } = useGetbankQuery({ limit: 10, offset: 0 });
    const { data: companyData, isLoading }: any = useGetCompanyByIdQuery(taskData?.company_id ? taskData?.company_id : cmpId ? +cmpId : '');
    const [addEstimate] = useAddEstimateMutation();
    const [addImages, { data }] = useAddImagesMutation();
    const ref: any = useRef(null);
    interface Values {
        address_from?: string,
        buyer_tin?: string,
        buyer_name?: string,
        buyer_address?: string,
        shipping_address?: string,
        shipping_method?: string,
        shipping_date?: Date,
        with_vat?: string,
        type?: string,
        tin?: string,
        bank?: number
    }
    const SignupSchema = Yup.object().shape({
        task_name: Yup.string()
            .required(t('required')),
        address_from: Yup.string()
            .required(t('required')),
        buyer_tin: Yup.string()
            .test(
                'type',
                t('required'),
                function (value) {
                    const options: any = this.options
                    const type = options?.parent?.tin;
                    if (type === '1' && !value) {
                        return false;
                    }
                    return true;
                }
            ),
        buyer_name: Yup.string()
            .required(t('required')),
        buyer_address: Yup.string()
            .required(t('required')),
        shipping_address: Yup.string()
            .required(t('required')),
        shipping_method: Yup.string()
            .required(t('required')),
        car_number: Yup.string()
            .test(
                'type',
                t('required'),
                function (value) {
                    const options: any = this.options
                    if (options.parent.shipping_method === 'delivery' && !value) {
                        return false
                    }
                    return true;
                }
            ),
        deposit: Yup.number()
            .required(t('required'))
            .min(0, t('required')),
    });

    const handleSetFile = useCallback((data: IFiles) => {
        setFiles({ ...files, ...data })
    }, [files])

    const handleDeleteFile = useCallback((name: keyof typeof files) => {
        setFiles((prev: IFiles) => {
            const prevFiles = { ...prev };
            delete prevFiles[name];
            return prevFiles
        })
    }, [])

    const handleSelectShippingDate = useCallback((value: string) => {
        setShippingDate(value)
    }, [])

    const handleChangeShowTable = useCallback(() => { setShowTable(!showTable) }, [showTable])

    const handleRecording = useCallback((url: string) => {
        setRecording(url)
    }, [])

    const handleAdd = useCallback((item: any) => {
        const invoiceValues = ref?.current ? ref?.current?.values : {};
        const price = item?.discount ? (item?.price - item?.price * item?.discount / 100) : item?.price
        const vat = invoiceValues?.with_vat === "true" ? price * 0.2 : 0;
        const sum = (price + vat) * 3;
        setProducts([...products, { ...item, vat: +vat.toFixed(2), sum: +sum.toFixed(2), discount: item?.discount?.toFixed(1) }])
        setShowTable(false)
    }, [products])

    const property = useMemo(() => {
        return ["name", "measurement", "count", "price", "discount", "sum",]
    }, [])
    const propertyWithVat = useMemo(() => {
        return ["name", "measurement", "count", "price", "discount", "vat", "sum",]
    }, [])
    const handleDeleteAnyDocument = useCallback((ind: number) => {
        const prevFilesAnyDocument = files?.any_document ? [...files?.any_document] : [];
        prevFilesAnyDocument.splice(ind, 1)
        setFiles({ ...files, any_document: prevFilesAnyDocument })
    }, [files])
    return (
        <div className={styles.cont}>
            <div className={styles.header}>
                <span className={styles.headerTitle}>{t('Estimate')}</span>
                <div className={styles.closeButton} onClick={() => { setEstimateTask(false) }}><img src={closeIcon} /></div>
            </div>
            <div className={styles.main}>
                <Formik
                    initialValues={{
                        tin: '1',
                        task_name: '',
                        shipping_method: 'delivery',
                        with_vat: 'true'
                    }}
                    innerRef={ref}
                    validationSchema={SignupSchema}
                    onSubmit={(
                        values: Values,
                        { setSubmitting }: FormikHelpers<Values>
                    ) => {
                        setSubmited(true)
                        const { type, tin, ...rest } = values;
                        if (products.length === 0) return
                        addEstimate({
                            ...rest,
                            files: files?.any_document?.map((el) => ({ url: el })),
                            shipping_date: shippingDate,
                            status: 'created',
                            with_vat: values?.with_vat === 'true',
                            task_type: taskData?.task_type_id ? taskData?.task_type_id : localStorage.getItem('taskTypeId'),
                            company: taskData?.company_id ? taskData?.company_id : localStorage.getItem('cmpId'),
                            recording: recording ? recording : '',
                            values: products,
                            bank: values.bank ? values.bank : ''
                        })
                            .then((res: any) => {
                                if (res?.data?.id) {
                                    toast.success('Գործընթացը հաջողությամբ կատարվել է');
                                    navigate('/profile/dashboard')
                                }
                            })

                    }}
                >
                    {({ errors, touched, values }: any) => (
                        <Form className='w-100' >
                            {/* <div className='d-flex justify-content-between'>
                            <h1 className={styles.pageHeading}>{t('Estimate')}</h1>
                            <div className='d-none d-md-flex'>
                            </div>
                        </div> */}
                            <div className={styles.taskNameDiv}>
                                <CustomInput
                                    name='task_name'
                                    type='text'
                                    title={t('taskName')}
                                    classInput={styles.inputeStyle}  />
                            </div>
                            <>
                                <div className={`${styles.sectionHeading}`}><span className={styles.titleStyle}>{t('buyerData')}</span></div>
                                <CustomRadio
                                    radioArray={[
                                        { label: t("physicalPerson"), value: "0" },
                                        { label: t('legalPerson'), value: "1" },
                                    ]}
                                    label={t('')}
                                    name={'tin'}
                                />
                                <div className={styles.wrapDiv}>
                                {values?.tin === '1' && <CustomInput name='buyer_tin' title={t('buyerTin')} classInput={styles.inputeStyle}  />}
                                <CustomInput name='buyer_name' title={t('buyerName')} classInput={styles.inputeStyle}  />
                                <CustomInput name='buyer_address' title={t('buyerAddress')} classInput={styles.inputeStyle}  />
                                <CustomInput name='shipping_address' title={t('shippingAddress')} classInput={styles.inputeStyle}  />
                                <CustomInput name='deposit' title={t('deposit')} type='number' classInput={styles.inputeStyle}  />
                                </div>
                                <div className={styles.picerDiv}>
                                <BankScore
                                    values={values}
                                    name='buyer_bank_number'
                                    second_name='bank'
                                    title={t('bankScore')}
                                    list={banks?.map((el: IBankItem) => ({
                                        src: `${process.env.REACT_APP_API_KEY}${el?.icon}`,
                                        id: el?.id,
                                        name: el?.name
                                    }))}
                                    className='taskInput'
                                />
                                <CustomSelectFormik
                                    className='taskInput'
                                    label={t("addressFrom")}
                                    list={companyData?.address?.map((el: any) => ({ name: el?.name, id: el?.name }))}
                                    name='address_from'
                                />
                                </div>
                                <CustomRadio
                                    radioArray={[
                                        { label: t("delivery"), value: "delivery" },
                                        { label: t('selfTransport'), value: "selfTransport" },
                                    ]}
                                    label={t('shippingMethod')}
                                    name={'shipping_method'}
                                />{values?.shipping_method === 'delivery' && <CustomInput name='car_number' title={t('carNumber')} classInput={styles.inputeStyle} />}
                                <CustomDayPicker
                                    disabled={{ from: new Date(2000, 1, 1), to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1) }}
                                    mode='single'
                                    setSelected={handleSelectShippingDate}
                                    name='shipping_date'
                                    title={t('shipping_date')}
                                    className='taskInput'
                                    selected={shippingDate}
                                    submited={submited}
                                />
                                <div>
                                    <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}><span className={styles.titleStyle}>{t('attachYourDocument')}</span></div>
                                    <div className={`d-flex align-items-center ${styles.documentLabel}`}>
                                        <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                                            <input type='file' style={{ display: 'none' }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                                                    if (file) {
                                                        const formdata = new FormData();
                                                        formdata.append('file_url', file)
                                                        addImages(formdata)
                                                            .then((res: any) => {
                                                                if (res.data) {
                                                                    let any_document = files && files.any_document ? [...files.any_document] : [];
                                                                    any_document.push(res.data)
                                                                    handleSetFile({ any_document })
                                                                }
                                                            })
                                                    }
                                                }}
                                            />
                                                <div className={styles.defautDoc}> <img className={styles.iconDoc} src={docIcon} /><span className={styles.label}>{t('attachFilesHere')}</span></div>
                                        </label>
                                        {/* <span className={styles.label}>{t('attachFilesHere')}</span> */}
                                    </div>
                                    {files?.any_document && files?.any_document?.length > 0 && <div className='d-flex gap-2'>
                                        {files?.any_document?.map((el: string, ind: number) => {
                                            return <ComponentWithBorder
                                                onClick={() => handleDeleteAnyDocument(ind)}
                                                key={ind}
                                                img={`${process.env.REACT_APP_API_KEY}${el}`} />
                                        })}
                                    </div>}
                                </div>
                                <CustomRadio
                                    radioArray={[
                                        { label: t("withVat"), value: "true" },
                                        { label: t('withoutVat'), value: "false" },
                                    ]}
                                    label={t('')}
                                    name={'with_vat'}
                                    onChangeSecond={() => setProducts([])}
                                />
                                <div className={styles.addProduct} >
                                    <span className={styles.titleStyle}>Product List</span>
                                    <div className={styles.addProductItem} onClick={handleChangeShowTable}>
                                        <div className={styles.addIconDiv}><img className={styles.addIcon} src={addProductIcon} /></div>
                                        <span className={styles.addProductText}>{t('addProduct')}</span>
                                    </div>
                                </div>
                                {/* <div className={styles.addProduct} onClick={handleChangeShowTable}>
                                    <span>{t('addProduct')}</span>
                                    <AddIcon />
                                </div> */}
                            </>
                            <CustomizedTables
                                withDiscount={true}
                                rows={products}
                                name={t('product')}
                                property={values?.with_vat === 'true' ? propertyWithVat : property}
                            />
                            {submited && products.length === 0 && <div className='error'>{t('required')}</div>}
                            <div>
                                <div className={styles.sectionHeading}><span className={styles.titleStyle}>{t('voiceMessage')}</span></div>
                                <Recording handleRecording={handleRecording} />
                            </div>
                            <div className={styles.buttonDiv}>
                                <CustomButtosWithIcon
                                    title={t('deleteInfoDialogCancelButtonTitle')}
                                    type={'button'}
                                    onClick={() => { setEstimateTask(false) }}
                                    icon=''
                                    titleColor="black"
                                    color="white"
                                />
                                <CustomButtosWithIcon
                                    title={t('create')}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                                    </svg>}
                                    type='submit'
                                    onClick={() => setSubmited(true)}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <ProductDialog
                type='estimate'
                open={showTable}
                handleClose={handleChangeShowTable}
                handleAdd={handleAdd} />

        </div>

    );
}


export default EstimateTask;