import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { CustomInput, CustomButton, CustomDateInput } from "../../Components";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import styles from "../../App.module.scss";
import RegisSvg from "../../Components/SvgCompoents/RegisSvg";
import { useTranslation } from "react-i18next";
import {
  useCheckEmailMutation,
  useSignUpMutation,
} from "../../store/services/auth";
import Finished from '../../Components/SvgCompoents/Finished';
import { requestForToken } from "../../firebaseNotifications/firebase";

export default function AsUser() {
  const navigate = useNavigate();
  const [signUpRequest, { isSuccess }] = useSignUpMutation();
  const [checkEmail] = useCheckEmailMutation();
  const [showCode, setShowCode] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  interface Values {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    birth_date: Date;
    legal_type?: string;
    phone_number?: string;
    cover_image?: string;
    avatar_image?: string;
    language?: string;
  }
  const { t, i18n } = useTranslation();
  const SignupSchema = Yup.object().shape({
    email: Yup.string().trim().email(t("emailError")).required(t("required")),
    password: Yup.string().trim().required(t("required")),
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    phone_number: Yup.string()
      .required(t("required"))
      .test(
        'unique',
        t("phoneNumberError").toString(),
        (value: any) => /^[0-9\b]+$/.test(value)
      ),
  });
  const handleshowCode = useCallback(() => setShowCode(true), []);
  const handleShowCongratulations = useCallback(() => setShowCongratulations(true), [])

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.cont}`}
    >
      <div className={styles.RegisSvg}>
        <RegisSvg />
      </div>
      <div className={styles.formCont}>
        <div className={styles.heading}>{t("signUpAsUser")}</div>
        <div className={`${styles.subHeading} d-none d-md-flex`}>
          <span>{t('subTitle')}</span>
          <span onClick={() => { navigate("/"); }}>
            {t('signInUp')}
          </span>
        </div>
        <Formik
          initialValues={{
            email: "",
            phone_number: "",
            password: "",
            first_name: "",
            last_name: "",
            birth_date: new Date(),
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            // signUpRequest({ ...values, user_role: 3 })
            checkEmail({ email: values.email.trim() })
              .then((res: any) => {
                if (res?.error) return;
                if (showCode) {
                  signUpRequest({
                    ...values,
                    role: 3,
                    legal_type: 1,
                    company_name: "",
                    tin: "",
                    email: values.email.trim(),
                    password: values.password.trim(),
                    // birth_date: new Date(),
                    // email_code: "111111",
                    // phone_number: "",
                    cover_image: "",
                    avatar_image: "",
                    language: i18n.language === 'am' ? 'hy' : i18n.language,
                  })
                    .then((res: any) => {
                      if (res.data && res.data.access) {
                        requestForToken();
                        navigate('/profile/congratulations')
                      }
                    });
                } else {
                  handleshowCode()
                }

              })

          }}
        >
          {({ errors, touched }: any) => (
            <Form>
              {showCode ? <>
                <CustomInput name="email_code" title={t("code")} />
                <CustomButton
                  title={t("signUp")}
                  type="submit"
                  onClick={() => console.log('log')}
                />
              </>

                : <>
                  <div className={`d-md-flex justify-content-between ${styles.inLine}`}>
                    <CustomInput className={styles.customInput} name="first_name" title={t("firstName")} />
                    <CustomInput className={styles.customInput} name="last_name" title={t("lastName")} />
                  </div>
                  <CustomInput className={styles.customInput} name="email" title={t("emailAdress")} />
                  <CustomInput className={styles.customInput} name="phone_number" title={t("phoneNumber")} />
                  <CustomInput className={styles.customInput} name="password" type="password" title={t("password")} />
                  {/* <CustomDateInput
                    name="birth_date"
                    title={t("birthData")}
                    type="date"
                  /> */}
                  {/* <CustomInput
                      name="birth_date"
                      title={t("birthData")}
                      type="date"
                    /> */}
                    <CustomButton
                      className={styles.regisButton}
                      title={t("signUp")}
                      type="submit"
                      onClick={() => console.log("dbgfdjg")}
                    />
                </>}
            </Form>
          )}
        </Formik>
        {/* <div className={styles.bottomText}>
          {t("dontHaveAnAccount")}{" "}
          <span
            className={styles.signUp}
            onClick={() => navigate("/registration")}
          >
            {" "}
            {t("signIn")}
          </span>
        </div> */}
      </div>
    </div>
  );
}
