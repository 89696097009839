import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomInput, CustomButton } from '../../../Components';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from "yup";
import styles from '../../../App.module.scss';
import { useTranslation } from 'react-i18next';
import { useCheckEmailMutation, useSignUpMutation } from '../../../store/services/auth';
import Finished from '../../../Components/SvgCompoents/Finished';
import { requestForToken } from '../../../firebaseNotifications/firebase';

export default function Physical() {
  const [active, setAtcive] = useState<string>('physical');
  const navigate = useNavigate();
  const [signUpRequest] = useSignUpMutation();
  const [checkEmail, { isSuccess }] = useCheckEmailMutation();
  const [showCode, setShowCode] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  interface Values {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    password: string
  }
  const { t, i18n } = useTranslation();
  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t('required')),
    last_name: Yup.string()
      .required(t('required')),
      phone_number: Yup.string()
    .required(t("required"))
    .test(
      'unique',
      t("phoneNumberError").toString(),
      (value:any) => /^[0-9\b]+$/.test(value)
    ),
    email: Yup.string().trim().email(t('emailError'))
      .required(t('required')),
    password: Yup.string().trim()
      .required(t('required')),
  });
  const handleClick = useCallback((name: string) => {
    setAtcive(name)
  }, [])

  const onSubmit = useCallback(async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    await checkEmail({ email: values.email.trim() })
      .then((res: any) => {
        if (res?.error) return
        if (showCode) {
          signUpRequest({
            ...values,
            email: values.email.trim(),
            password: values.password.trim(),
            role: 6,
            legal_type: 0,
            tin: '',
            company_name: '',
            birth_date: new Date(),
            language: i18n.language==='am'?'hy':i18n.language,
            cover_image: "",
            avatar_image: ""
          })
            .then((res: any) => {
              if (res.data && res.data.access) {
                requestForToken();
                navigate('/profile/congratulations')
              }
            });
        } else {
          handleshowCode()
        }
      })
  }, [isSuccess])
  const handleshowCode = useCallback(() => setShowCode(true), []);
  const handleShowCongratulations = useCallback(() => setShowCongratulations(true), []);
  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: ''
      }}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }: any) => (
        <Form >
          {showCode
            ? <>
              <CustomInput name="email_code" title={t("code")} />
              <CustomButton
                title={t("signUp")}
                type="submit"
                onClick={() => console.log('log')}
              />
            </>
            : <>
              <div className={`d-flex justify-content-between  ${styles.inLine}`}>
                <CustomInput className={styles.customInput} name='first_name' title={t('firstName')} />
                <CustomInput className={styles.customInput} name='last_name' title={t('lastName')} />
              </div>
              <CustomInput className={styles.customInput} name='phone_number' type="number" title={t('phoneNumber')} />
              <CustomInput className={styles.customInput} name='email' title={t('emailAdress')} />
              <CustomInput className={styles.customInput} name='password' type='password' title={t('password')} />
              <CustomButton className={styles.regisButton} title={t('signIn')} type='submit' onClick={() => console.log('dbgfdjg')} />
            </>}
        </Form>
      )}
    </Formik>
  );
}


