import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "../DeleteDialog.module.scss";
import CustomButtosWithIcon from "../../Components/CustomButtosWithIcon";
import { log } from "console";
interface IProps {
  deleteTitle?: string;
  title?: string;
  open?: any;
  handleClose?: () => void;
  handleDelete?: () => void;
  data?: any;
  avatar_image?: string;
  // setOpenModal?:any
  // openModal?:any
}
const DeleteAccountDialog = ({
  open,
  title,
  handleClose,
  handleDelete,
  data,
  avatar_image,
  deleteTitle,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={styles["dialogCont"]}>
        <div className={styles.header}>
          <span className={styles.deleteTitle}>{deleteTitle}</span>
          <div className={styles.infoDiv}>
         { avatar_image?  <div className={styles.iconDiv}>
              <img className={styles.iconStyle} src={avatar_image} />
            </div>:<div className={styles.imgContainer}><img src={"/managerDefaultImg.svg"}/></div>}
            <div className={styles.spanDiv}>
              <span className={styles.text}>
                {data?.user?.first_name ? data?.user?.first_name : data?.name}
              </span>
              <span className={styles.text}>{data?.user?.last_name}</span>
            </div>
          </div>
        </div>

        <DialogContent>
          <div className={`d-flex flex-column ${styles.heading}`}>
            {title ? title : t("deleteInfoDialogTitle")}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", gap: "2" }}
        >
          <CustomButtosWithIcon
            title={t("deleteInfoDialogCancelButtonTitle")}
            type={"button"}
            onClick={handleClose}
            icon=""
            titleColor="black"
            color="white"
          />
          <CustomButtosWithIcon
            // title={t('yes')}
            title="Delete"
            type={"button"}
            onClick={handleDelete}
            icon=""
            color="#EE1D52"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default DeleteAccountDialog;
